/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import '../../VodafoneIdea.scss'
// import "../PriCaf/PRICafForm.css";
import VodafoneImg from '../../../../../../assets/images/vil-red-logo.png'
import './PriIPCentrixCafForm.css'
import SectionHeader from '../../vilBranding/SectionHeader'
import TermsConditionNew from '../../termsCondition'
import {
	useLoadRomsFormFieldListData,
	useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { useAuthContext } from '../../../../../../context/auth.context'
import { TERMS_VERSION, getVersionTerms, encode } from '../../cafCrf.util'
import { Spinner, Center, useTheme } from '@chakra-ui/react'

const PriIPCentrixCafForm = ({ formData, queueData }) => {
	const [cafData, setCafData] = useState(false)
	const [priCafDataupdated, setUpdatedCafData] = useState(null)
	const [companySeal, setCompanySeal] = useState(null)
	const [photoGraph, setPhotograph] = useState(null)
	const [authorizedSignatoryName, setAuthSignName] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [acctMngSignImageType, setacctMngSignImageType] = useState(null)
	const [photoGraphImageData, setphotoGraphImageData] = useState(null)
	const [photoGraphImageType, setphotoGraphImageType] = useState(null)

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadCompanySeal } = useLoadCompanySeal()
	let componentRef = React.useRef()
	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const CustomComponent = () => {
		return (
			<div className='section2 font8'>
				<div className=''>
					<p className='over_hid'>
						<img src={VodafoneImg} />
					</p>
					<p className='gen'>
						GENERAL TERMS AND CONDITIONS (“GTC”) for SIP Central Service{' '}
					</p>
				</div>
			</div>
		)
	}

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCafData(false)
					setUpdatedCafData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	const fetchCompanySeal = () => {
		loadCompanySeal(
			{
				form_id: 1571,
				field_id: 0,
				activity_id: queueData.activity_id,
			},
			{
				onSuccess: async res => {
					let companySeal = ''
					let photoGraph = ''
					let authorizedSignatoryName = ''
					let data = []
					if (res.length > 0) {
						data = JSON.parse(res[0].data_entity_inline).form_submitted
					} else {
						data = []
					}
					data.forEach(function (fieldObj, index) {
						if (Number(fieldObj.field_id) === 14980) {
							companySeal = fieldObj.field_value
						}
						if (Number(fieldObj.field_id) === 14979) {
							photoGraph = fieldObj.field_value
						}

						if (Number(fieldObj.field_id) === 14978) {
							authorizedSignatoryName = fieldObj.field_value
						}
					})
					setCompanySeal(companySeal)
					setPhotograph(photoGraph)
					setAuthSignName(authorizedSignatoryName)
				},
			}
		)
	}

	useEffect(() => {
		setCafData(true)
		fetchCompanySeal()
		fetchFieldListData()
	}, [])

	useEffect(
		() => {
			let data = priCafDataupdated
			if (!!data) {
				if (!!companySeal) {
					// customer company seal
					let custCompanySeal = companySeal
					loadImageBlob(custCompanySeal, (data, type) => {
						setImageData(data)
						setImageType(type)
					})
				}
				if (!!data[14781].value) {
					// manager sign url
					let acctManagerSignUrl = data[14781].value
					loadImageBlob(acctManagerSignUrl, (data, type) => {
						setacctMngSignImageData(data)
						setacctMngSignImageType(type)
					})
				}
				if (!!photoGraph) {
					// customer photograph url
					let photographUrl = photoGraph
					loadImageBlob(photographUrl, (data, type) => {
						setphotoGraphImageData(data)
						setphotoGraphImageType(type)
					})
				}
			}
		},
		[priCafDataupdated],
		companySeal,
		photoGraph,
		authorizedSignatoryName
	)

	let PriIPCentrixCafContentView = ''
	if (!!priCafDataupdated) {
		let firstCaps = ''
		let secondBold = ''
		let Signcss

		if (!!authorizedSignatoryName) {
			let customerSignSplit = authorizedSignatoryName.split(' ')

			if (customerSignSplit.length > 1) {
				firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
				secondBold = customerSignSplit['1']
				Signcss = 'signate_img input_sign'
			} else {
				firstCaps = customerSignSplit['0']
				secondBold = ''
				Signcss = 'signate_img input_sign under_line'
			}
		}

		PriIPCentrixCafContentView = (
			<div>
				<div
					style={{ textAlign: 'left' }}
					className='tab_content'
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<SectionHeader name='Customer Application Form'>
									{' '}
								</SectionHeader>
								<div className='over_hid w_85'>
									<div className=''>
										<div className='textcenter'>
											Unique ID
											<input
												className='form_col brdr_b1 '
												type='text'
												name=''
												value=''
											/>
										</div>
									</div>
								</div>
								<div className='over_hid pad_t10'>
									<div className='fleft width75'>
										<p className='ligthbg'>Name and Address </p>
										<p className='auth'>
											{' '}
											Name of Company
											<input
												className='form_col width80 flright'
												type='text'
												name=''
												value={priCafDataupdated[14577].value}
												readOnly
											/>
										</p>
										<p className='auth'>
											Company's Registered
											<input
												className='form_col width80 flright'
												type='text'
												name=''
												value={priCafDataupdated[14578].value}
												readOnly
											/>
										</p>
										<div className='auth margin0'>
											<span className='width54px'>Address</span>
											<input
												className='form_col width85 flright'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												Landmark
												<input
													className='form_col width65'
													type='text'
													name=''
													value={priCafDataupdated[14579].value}
													readOnly
												/>
											</div>
											<div className='fleft width25'>
												State
												<input
													className='form_col width65'
													type='text'
													name=''
													value={priCafDataupdated[14581].value}
													readOnly
												/>
											</div>
											<div className='fleft width25'>
												Pincode
												<input
													className='form_col width60 flright'
													type='text'
													name=''
													value={priCafDataupdated[14582].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width58'>
												Landline No. +91
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14583].value}
													readOnly
												/>
											</div>
											<div className='fleft width42'>
												<span className='faxn'>Fax No.</span>
												<input
													className='form_col width52 flright'
													type='text'
													name=''
													value={priCafDataupdated[14584].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												CIN Number
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14585].value}
													readOnly
												/>
											</div>
											<div className='fleft width50'>
												<span className='pad_t5'>Company PAN Card Number</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14586].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='flright cross_fill'>
										<div className='crossign' style={{ height: '100px' }}>
											{photoGraphImageData !== null && photoGraphImageData ? (
												<div
													style={{
														padding: '5px',
														position: 'relative',
														textAlign: 'center',
													}}
													className='signate_img'
												>
													<img
														style={{ height: '90px' }}
														src={
															photoGraphImageData !== null
																? 'data:' +
																  photoGraphImageType +
																  ';base64,' +
																  encode(photoGraphImageData)
																: ''
														}
														alt={'PhotoGraph with Crosssign'}
													/>
													{firstCaps !== '' ? (
														<span
															style={{
																padding: '10px',
																// position: 'absolute',
																left: '-20px',
																top: '20px',
															}}
															className={Signcss}
														>
															{firstCaps + '  .  '}
															<span className='under_line'>{secondBold}</span>
														</span>
													) : (
														''
													)}
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
								<p className='ligthbg'>Basic Organisation Details </p>
								<div className='over_hid '>
									<div className='fleft width49'>
										<p className='margin0'>
											<strong>Address 1 (Authorised Signatory Location)</strong>
										</p>
										<div className='auth margin0 pad_5t'>
											<span className='width54px'>Name</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14589].value}
												readOnly
											/>
										</div>
										<div className='auth margin0'>
											<span className='width54px'>Father's/ Husbends</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14590].value}
												readOnly
											/>
										</div>

										<div className='auth margin0'>
											<span className='width54px'>Nationality</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14591].value}
												readOnly
											/>
										</div>
										<div className='auth margin0'>
											<span className='width54px'>Billing Address</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14592].value}
												readOnly
											/>
										</div>
										<div className='auth margin0'>
											<span className='width54px'>&nbsp;</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width58'>
												Landmark
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14593].value}
													readOnly
												/>
											</div>
											<div className='fleft width42'>
												<span className='pad_l10'>City</span>
												<input
													className='form_col width70 flright'
													type='text'
													name=''
													value={priCafDataupdated[14594].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width58'>
												State *
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14595].value}
													readOnly
												/>
											</div>
											<div className='fleft width42'>
												<span className='pad_l10'>Pincode</span>
												<input
													className='form_col width60 flright'
													type='text'
													name=''
													value={priCafDataupdated[14596].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												Mobile No. +91
												<input
													className='form_col width50'
													type='text'
													name=''
													value={priCafDataupdated[14597].value}
													readOnly
												/>
											</div>
											<div className='fleft width50'>
												<span className='faxn'>Landline No. +91</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14598].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 pad_5t'>
											<span className='width54px'>Email ID</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14599].value}
												readOnly
											/>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width33'>
												Designation
												<input
													className='form_col width30'
													type='text'
													name=''
													value={priCafDataupdated[14600].value}
													readOnly
												/>
											</div>
											<div className='fleft width33'>
												Emp.Code
												<input
													className='form_col width30'
													type='text'
													name=''
													value={priCafDataupdated[14601].value}
													readOnly
												/>
											</div>
											<div className='fleft width33'>
												Vehicle No.
												<input
													className='form_col flright width33'
													type='text'
													name=''
													value={priCafDataupdated[14602].value}
													readOnly
												/>
											</div>
										</div>
										<p>*Mandatory for GST registered companies</p>
										<div className='check margin0 over_hid'>
											<div className='fleft width20'>
												GSTIN
												{priCafDataupdated[14622].value === 'GSTIN' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
											<div className='fleft width20'>
												UIN
												{priCafDataupdated[14622].value === 'UIN' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
											<div className='fleft width20'>
												GST_ISD
												{priCafDataupdated[14622].value === 'GST_ISD' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
									</div>
									<div className='flright width49'>
										<p className='margin0'>
											<strong>Address 2 (Key contract Location)</strong>
										</p>
										<div className='auth margin0 pad_5t'>
											<span className='width54px'>Name</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14604].value}
												readOnly
											/>
										</div>
										<div className='auth margin0'>
											<span className='width54px'>Address</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14605].value}
												readOnly
											/>
										</div>
										<div className='auth margin0'>
											<span className='width54px'>&nbsp;</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width58'>
												Landmark
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14606].value}
													readOnly
												/>
											</div>
											<div className='fleft width42'>
												<span className='pad_l10'>City</span>
												<input
													className='form_col width70 flright'
													type='text'
													name=''
													value={priCafDataupdated[14607].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width58'>
												State *
												<input
													className='form_col width70'
													type='text'
													name=''
													value={priCafDataupdated[14608].value}
													readOnly
												/>
											</div>
											<div className='fleft width42'>
												<span className='pad_l10'>Pincode</span>
												<input
													className='form_col width60 flright'
													type='text'
													name=''
													value={priCafDataupdated[14609].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												Mobile No. +91
												<input
													className='form_col width50'
													type='text'
													name=''
													value={priCafDataupdated[14610].value}
													readOnly
												/>
											</div>
											<div className='fleft width50'>
												<span className='faxn'>Landline No. +91</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14611].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 pad_5t'>
											<span className='width54px'>Email ID</span>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14612].value}
												readOnly
											/>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width33'>
												Designation
												<input
													className='form_col width30'
													type='text'
													name=''
													value={priCafDataupdated[14613].value}
													readOnly
												/>
											</div>
											<div className='fleft width33'>
												Emp.Code
												<input
													className='form_col width30'
													type='text'
													name=''
													value={priCafDataupdated[14614].value}
													readOnly
												/>
											</div>
											<div className='fleft width33'>
												Vehicle No.
												<input
													className='form_col flright width33'
													type='text'
													name=''
													value={priCafDataupdated[14615].value}
													readOnly
												/>
											</div>
										</div>
										<p className='margin0'>
											<strong>Organisation Type </strong>
										</p>
										<div className='check margin0 over_hid fon8_5'>
											<div className='fleft pad_r3'>
												{priCafDataupdated[14616].value === 'Partner Ship' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Partnership
											</div>
											<div className='fleft pad_r3'>
												{priCafDataupdated[14616].value === 'Proprietorship' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Proprietorship
											</div>
											<div className='fleft pad_r3'>
												{priCafDataupdated[14616].value ===
												'Private Limited' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Private Limited{' '}
											</div>
											<div className='fleft pad_r3'>
												{priCafDataupdated[14616].value === 'Public Sector' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Public Sector
											</div>
											<div className='fleft'>
												{priCafDataupdated[14616].value === 'Government' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Governament
											</div>
										</div>
										<div className='margin0 over_hid pad_5t'>
											<div className='fleft'>
												<span className=''>
													{priCafDataupdated[14616].value === 'Others' ? (
														<input
															className='form_col'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
													Others
												</span>{' '}
												<span className='auth'>
													<input
														className='form_col width60 flright'
														type='text'
														name=''
														value={priCafDataupdated[15033].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
										<div className='check margin0 over_hid typecheck'>
											<div className='fleft width12 pad_t2'>
												<strong>Telemarketer</strong>
											</div>
											<div className='fleft width12'>
												{priCafDataupdated[14617].value === 'Yes' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Yes
											</div>
											<div className='fleft width12'>
												{priCafDataupdated[14617].value === 'No' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												No
											</div>
										</div>
										<div className='auth margin0'>
											{' '}
											If Yes, Telemarketer Registration Number
											<input
												className='form_col width40'
												type='text'
												name=''
												value={priCafDataupdated[14618].value}
												readOnly
											/>
										</div>
										<div className='check margin0 over_hid typecheck'>
											<div className='fleft pad_t2'>
												<strong>OSP &nbsp; &nbsp; </strong>
											</div>
											<div className='fleft width12'>
												{priCafDataupdated[14619].value === 'Yes' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Yes
											</div>
											<div className='fleft width12'>
												{priCafDataupdated[14619].value === 'No' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												No
											</div>
										</div>
										<div className='auth margin0'>
											{' '}
											If Yes, OSP Registration Number
											<input
												className='form_col width40'
												type='text'
												name=''
												value={priCafDataupdated[14620].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className='check margin0 over_hid typecheck'>
									<div className='fleft width12 pad_t2'>GST Customer Type</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'Normal' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Normal
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'SEZ' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SEZ
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'Diplomat' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Diplomat
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'Embassy' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Embassy
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'Consulate' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Consulate
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value === 'Special Agency' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Special Agency
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14623].value ===
										'Person/Class of Persons' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Person/Class of Persons
									</div>
								</div>
								<p className='auth'>
									GSTIN / UIN / GST_ISD No
									<input
										className='form_col wid50'
										type='text'
										name=''
										value={priCafDataupdated[14624].value}
										readOnly
									/>
								</p>
								<div className='auth'>
									<span className='width70px in_block'>
										GST Regd. Address**
									</span>
									<input
										className='form_col width78'
										type='text'
										name=''
										value={priCafDataupdated[14625].value}
										readOnly
									/>
								</div>
								<div className='auth'>
									<span className='width70px in_block'>State**</span>
									<input
										className='form_col width78'
										type='text'
										name=''
										value={priCafDataupdated[14626].value}
										readOnly
									/>
								</div>
								<p>If GST Address is diffrent from Billing Address</p>
								<div className='check over_hid typecheck gstcheck'>
									<div className='fleft width12 pad_t2'>GST Customer Dept</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'IT' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										IT
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Tech' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Tech
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Cust Service' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Cust Service
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Legal' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Legal
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Normal' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Finanace
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Purchase' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Purchase
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'SCM' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SCM
									</div>
									<div className='fleft width12'>
										{priCafDataupdated[14628].value === 'Delivery' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Delivery
									</div>
									<div className='fleft width20 pad_t2'>
										Others
										<input
											className='form_col oth_gst'
											type='text'
											name=''
											value={priCafDataupdated[15034].value}
											readOnly
										/>
									</div>
								</div>
								<p className='auth namsupp'>
									Name of Supporting Document
									<input
										className='form_col wid50'
										type='text'
										name=''
										value={priCafDataupdated[14629].value}
										readOnly
									/>
								</p>
								<p className='gsttext'>
									(GST Certificate, SEZ Certificate, Diploma Crtificate)
								</p>
								<div className='over_hid '>
									<div className='fleft width49'>
										<p className='ligthbg'>Proof of Identity Details</p>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												Type of POI
												<input
													className='form_col width60'
													type='text'
													name=''
													value={priCafDataupdated[14631].value}
													readOnly
												/>
											</div>
											<div className='fleft width50'>
												<span className='faxn'>Document No.</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14632].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width45'>
												Date of Issue
												<input
													className='form_col width50'
													type='text'
													name=''
													value={priCafDataupdated[14633].value}
													readOnly
												/>
											</div>
											<div className='fleft width55'>
												<span className='faxn'>Place of Issue</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14634].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 pad_5t'>
											<span className=''>Issuing Authority</span>
											<input
												className='form_col width70 flright'
												type='text'
												name=''
												value={priCafDataupdated[14635].value}
												readOnly
											/>
										</div>
										<div className='auth margin0 pad_5t'>
											{' '}
											Multiple Connections (Please fill enclosed declaration)
										</div>
									</div>
									<div className='flright width49'>
										<p className='ligthbg'>Proof of Address Details</p>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width50'>
												Type of POA
												<input
													className='form_col width60'
													type='text'
													name=''
													value={priCafDataupdated[14640].value}
													readOnly
												/>
											</div>
											<div className='fleft width50'>
												<span className='faxn'>Document No.</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14641].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 over_hid pad_5t'>
											<div className='fleft width45'>
												Date of Issue
												<input
													className='form_col width50'
													type='text'
													name=''
													value={priCafDataupdated[14642].value}
													readOnly
												/>
											</div>
											<div className='fleft width55'>
												<span className='faxn'>Place of Issue</span>
												<input
													className='form_col width45 flright'
													type='text'
													name=''
													value={priCafDataupdated[14643].value}
													readOnly
												/>
											</div>
										</div>
										<div className='auth margin0 pad_5t'>
											<span className=''>Issuing Authority</span>
											<input
												className='form_col width70 flright'
												type='text'
												name=''
												value={priCafDataupdated[14644].value}
												readOnly
											/>
										</div>
										<div className='auth margin0 pad_5t'>
											<span className=''>Existing VIL Numbers (Count)</span>
											<input
												className='form_col width55 flright'
												type='text'
												name=''
												value={priCafDataupdated[14645].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width40'>
										<span className=''>Tariff Plan applied</span>
										<input
											className='form_col width65 flright'
											type='text'
											name=''
											value={priCafDataupdated[14637].value}
											readOnly
										/>
									</div>
									<div className='fleft width60'>
										<span className=''>
											Value Added Services applied (if any)
										</span>
										<input
											className='form_col width55 flright'
											type='text'
											name=''
											value={priCafDataupdated[14638].value}
											readOnly
										/>
									</div>
								</div>
								<p className='ligthbg'>
									Local Reference (For National Outstation and Foreign National
									Customers)
								</p>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width45'>
										<span className=''>Name</span>
										<input
											className='form_col width85 flright'
											type='text'
											name=''
											value={priCafDataupdated[14647].value}
											readOnly
										/>
									</div>
									<div className='fleft width30'>
										<span className=''>Contact No.</span>
										<input
											className='form_col width65 flright'
											type='text'
											name=''
											value={priCafDataupdated[14648].value}
											readOnly
										/>
									</div>
									<div className='fleft width25'>
										<span className=''>Time&Date of Call</span>
										<input
											className='form_col width45 flright'
											type='text'
											name=''
											value={priCafDataupdated[14650].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width75'>
										<span className=''>Address</span>
										<input
											className='form_col width85 flright'
											type='text'
											name=''
											value={priCafDataupdated[14649].value}
											readOnly
										/>
									</div>
									<div className='fleft width25'>
										<span className=''>
											Calling Party's No.
											<br />
											<span className='fnt6'>(to be filled by POS)</span>
										</span>
										<input
											className='form_col width45 flright posinput'
											type='text'
											name=''
											value={priCafDataupdated[14651].value}
											readOnly
										/>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='ligthbg'>Current Requirement</p>
								<div className='over_hid '>
									<div className='fleft width49'>
										<div className='auth margin0 pad_5t over_hid'>
											<div className='fleft width55'>
												<span className=''>No. of connections required</span>
												<input
													className='form_col width25 flright'
													type='text'
													name=''
													value={priCafDataupdated[14653].value}
													readOnly
												/>
											</div>
											<div className='flright '>
												<span className=''>NDNC Regestration Opt IN</span>
												<input
													className='flright'
													type='checkbox'
													name=''
													value=''
												/>
											</div>
										</div>
										<div className='check margin0 over_hid fon8_5'>
											<div className='fleft'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('No commercial Call/ SMS') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												1. No commercial Call/ SMS{' '}
											</div>
											<div className='flright'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf(
														'Banking/ Insurance/ Financal Produts/ Credit Card'
													) >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												2. Banking/ Insurance/ Financal Produts/ Credit Card{' '}
											</div>
										</div>
										<div className='check margin0 over_hid fon8_5'>
											<div className='fleft'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Real Estate') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												3. Real Estate{' '}
											</div>
											<div className='fleft pad_l10'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Education') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												4. Education{' '}
											</div>
											<div className='fleft pad_l10'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Health') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												5. Health{' '}
											</div>
											<div className='flright'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Consumer goods and Automoblies') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												6. Consumer goods and Automoblies{' '}
											</div>
										</div>
										<div className='check margin0 over_hid fon8_5'>
											<div className='fleft'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf(
														'Communication/ Broadcasting/ Entertainment/ IT'
													) >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												7. Communication/ Broadcasting/ Entertainment/ IT{' '}
											</div>
											<div className='fleft pad_l10'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Tourism and Leisure') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												8. Tourism and Leisure{' '}
											</div>
										</div>
										<div className='check margin0 over_hid fon8_5'>
											<div className='fleft'>
												{priCafDataupdated[14654].value
													.split('|')
													.indexOf('Any other category') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												9. Any other category{' '}
											</div>
										</div>
										<p className='margin0'>Type of Service</p>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14655].value === 'Mobile  Voice' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Mobile Voice{' '}
											</div>
											<div className='fleft'>
												{priCafDataupdated[14655].value ===
												'Mobile Broadband 2G 3G' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Mobile Broadbond 2G/3G/4G{' '}
											</div>
											<div className='fleft'>
												{priCafDataupdated[14655].value ===
												'Wireline Voice (PRI)' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Wireline Voice (PRI){' '}
											</div>
											<div className='fleft'>
												{priCafDataupdated[14655].value ===
												'Domestic SIP Trunk' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Domestic SIP Trunk{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14655].value === 'SIP Central' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												SIP Central{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'Domestic Toll Free' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Domestic Toll Free{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'International Toll Free' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												International Toll Free{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'Audio Conferencing' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Audio Conferencing{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14655].value ===
												'Video Conferencing ' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Video Conferencing{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'2G/3G/4G API Solution' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												2G/3G/4G API Solution{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value === 'GVN' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												GVN{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'Cloud Telephony' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Cloud Telephony{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14655].value === 'Call Recording' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Call Recording{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14655].value ===
												'Mobile Applications' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Mobile Applications{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft pad_t3'>
												{' '}
												<strong>Internet of Things</strong>{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14656].value === 'Connectivity' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Connectivity{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14656].value ===
												'Managed Connectivity' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Managed Connectivity{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14656].value === 'SuperIOT' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Integrated IOT{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14656].value ===
												'Location Based Services' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Location tracking Solutions{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14656].value ===
												'Logistics Tracking' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Logistics Tracking{' '}
											</div>
											<div className='fleft pad_l_2 '>
												{priCafDataupdated[14656].value === 'Others' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Others{' '}
												<span className='in_subput'>
													<input
														className='form_col width60 flright'
														type='text'
														name=''
														value={priCafDataupdated[15035].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
										<p className='auth'>
											Tariff Plan
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14637].value}
												readOnly
											/>
										</p>
									</div>
									<div className='flright width49'>
										<p className='margin0'>Services on Voice</p>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[15026].value === 'NCUG' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												NCUG{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[15026].value === 'IR' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												IR{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[15026].value === 'Itemised Bill' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Itemised Bill{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[15026].value === 'GPRS' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												GPRS{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[15026].value === 'ISD' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												ISD{' '}
											</div>
											<div className='fleft pad_l_2 '>
												{priCafDataupdated[15026].value === 'Others' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Others{' '}
												<span className='in_subput'>
													<input
														className='form_col width60 flright'
														type='text'
														name=''
														value={priCafDataupdated[15036].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
										<br />
										<p className='margin0'>Value Added Services:</p>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14657].value === 'CCT' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												CCT{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14657].value === 'MCI' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												MCI{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14657].value ===
												'Enterprise Alerts' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Enterprise Alerts{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14657].value === 'VMC' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												VMC{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14657].value ===
												'Mobile Internet 2G/3G/4G' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Mobile Internet 2G/3G/4G{' '}
											</div>
										</div>
										<br />
										<div className='check margin0 over_hid'>
											<div className=''>
												{priCafDataupdated[14657].value === 'Others' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Others{' '}
												<span className='in_subput'>
													<input
														className='form_col wid83p flright'
														type='text'
														name=''
														value={priCafDataupdated[15037].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
										<br />
										<p className='margin0'>Devices</p>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14658].value === 'BlackBerry' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Blackberry{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'Smartphone' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Smartphone{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'Tablet' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Tablet{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'Netbook' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Netbook{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'USB Stick' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												USB Stick{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'Moible Wifi' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Moible Wifi{' '}
											</div>
										</div>
										<div className='check margin0 over_hid font_8'>
											<div className='fleft'>
												{priCafDataupdated[14658].value === 'Sharing Dock' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Sharing Dock{' '}
											</div>
											<div className='fleft pad_l_2'>
												{priCafDataupdated[14658].value === 'GSM Gateway' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												GSM Gateway{' '}
											</div>
										</div>
										<br />
										<div className='check margin0 over_hid'>
											<div className=''>
												{priCafDataupdated[14658].value === 'Others' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
												Others{' '}
												<span className='in_subput'>
													<input
														className='form_col wid83p flright'
														type='text'
														name=''
														value={priCafDataupdated[14659].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
										<br />
										<div className='check margin0 over_hid'>
											<div className=''>
												{' '}
												CUG Code{' '}
												<span className='in_subput'>
													<input
														className='form_col wid83p flright'
														type='text'
														name=''
														value={priCafDataupdated[14663].value}
														readOnly
													/>
												</span>{' '}
											</div>
										</div>
									</div>
								</div>
								<div className='auth over_hid custype pad_t5'>
									<div className='fleft width20 pad_7t'>
										E-Bill Opt In (Go Green)
									</div>
									<div className='fleft width30'>
										<input
											className='form_col fleft width30'
											type='text'
											name=''
											value={priCafDataupdated[14661].value}
											readOnly
										/>
									</div>
									<div className='flright width50'>
										{' '}
										E-Mail ID for sending bills
										<input
											className='form_col width50'
											type='text'
											name=''
											value={priCafDataupdated[14662].value}
											readOnly
										/>
									</div>
								</div>
								<p className='ligthbg'>Payment Details</p>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width70'>
										<span className=''>
											Name of Person Responsiable for Payments
										</span>
										<input
											className='form_col width55 flright'
											type='text'
											name=''
											value={priCafDataupdated[14665].value}
											readOnly
										/>
									</div>
									<div className='fleft width30'>
										<span className=''>Mobile No. +91</span>
										<input
											className='form_col width60 flright'
											type='text'
											name=''
											value={priCafDataupdated[14666].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check margin0 over_hid font_8'>
									<div className='fleft width30'> Billing Address</div>
									<div className='fleft pad_l_5'>
										{priCafDataupdated[14667].value ===
										'Authorised Sign Location' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Authorised Sign Location
									</div>
									<div className='fleft pad_l_5'>
										{priCafDataupdated[14667].value ===
										'Key Contact Location' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Key Contact Location
									</div>
									<div className='fleft '>
										{priCafDataupdated[14667].value ===
										'Company Registered Address' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Company Registered Address
									</div>
								</div>
								<div className='check margin0 over_hid font_8'>
									<div className='fleft pad_l_5 width30'> Payment Mode</div>
									<div className='fleft pad_l_5'>
										{priCafDataupdated[14668].value === 'ECS' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										ECS
									</div>
									<div className='fleft pad_l_5'>
										{priCafDataupdated[14668].value === 'SI' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										SI
									</div>
									<div className='fleft pad_l_5'>
										{priCafDataupdated[14668].value === 'CHEQUE' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										CHEQUE
									</div>
									<div className='fleft pad_l_5 pad_5t'>
										{' '}
										Check (for ECS and SI, please fill adational form){' '}
									</div>
								</div>
								<div className='check margin0 over_hid'>
									<div className='fleft'>
										{' '}
										Deposit Amount{' '}
										<span className='in_subput'>
											<input
												className='form_col width60 flright'
												type='text'
												name=''
												value={priCafDataupdated[14669].value}
												readOnly
											/>
										</span>{' '}
									</div>
									<div className='fleft pad_l10'>
										{' '}
										Deposit Type
										{priCafDataupdated[14670].value === 'Deposit' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
									</div>
									<div className='fleft pad_l_2'>
										{' '}
										Security
										{priCafDataupdated[14670].value === 'Security' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
									</div>
									<div className='fleft pad_l_2'>
										{' '}
										IR Deposit
										{priCafDataupdated[14670].value === 'IR Deposit' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
									</div>
									<div className='fleft pad_l_2 '>
										{' '}
										Others{' '}
										<span className='in_subput'>
											<input
												className='form_col width60 flright'
												type='text'
												name=''
												value={priCafDataupdated[14671].value}
												readOnly
											/>
										</span>{' '}
									</div>
								</div>
								<p className='ligthbg'>Customer Declaration</p>
								<div className='check margin0 over_hid'>
									<div className='fleft width70'>
										<div className='pad_r15_pri'>
											I/We agree to refundable security deposit and all charges
											associated with the services selected by me/us. I/We have
											read and terms and conditions mentioned overleaf and
											unconditionally accept them as binding on me/us. I/'We
											have understood all the rates. tariffs and other related
											co it telecommunications services be provided inside and
											outside India as applicable as on this date and as amended
											from time to time. I/We hereby undertake to pay all
											charges raised on account Of Services availed, l/We
											undertake that in the event this connection is used for
											any telemarketing purpose. I/We shall be solely
											responsible for registration of the connection with the
											mandated statutory authorities. further declare and
											undertake that above information provided by me/us is true
											and correct in all respect. I/We understand that the SIM
											non-transferable. Any misuse of SIM by customer or any
											other person is illegal and shall be liable for criminal
											actions.
										</div>
										<div className='check margin0 over_hid pad_t10'>
											<div className=''>
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
												I agree to receive a welcome mail giving details of
												service(s) subscribed{' '}
											</div>
										</div>
									</div>
									<div className='fleft width30 '>
										<div className='signate_box'>
											<table>
												<tbody>
													<tr>
														<td width='50%'>
															{firstCaps !== '' ? (
																<div
																	style={{
																		padding: '10px',
																		position: 'absolute',
																	}}
																	className={Signcss}
																>
																	{firstCaps + '  .  '}
																	<span className='under_line'>
																		{secondBold}
																	</span>
																</div>
															) : (
																''
															)}
														</td>
														<td width='50%'>
															{imageData !== null && imageData !== '' ? (
																<div
																	style={{ padding: '0px' }}
																	className='signate_img'
																>
																	<img
																		src={
																			imageData !== null
																				? 'data:' +
																				  imageType +
																				  ';base64,' +
																				  encode(imageData)
																				: ''
																		}
																		alt={'Company Seal'}
																	/>
																</div>
															) : (
																''
															)}
														</td>
													</tr>
													<tr>
														<td>Sign &amp; Seal</td>
														<td>
															<spa className=' pad_l_2 '>
																{' '}
																Date{' '}
																<span className='in_subput'>
																	<input
																		className='form_col width60 flright'
																		type='text'
																		name=''
																		value={priCafDataupdated[15574].value}
																		readOnly
																	/>
																</span>{' '}
															</spa>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<p className='ligthbg'>VIL Internet Use</p>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width60'>
										<span className=''>Enterprise Description Code</span>
										<input
											className='form_col width65 flright'
											type='text'
											name=''
											value={priCafDataupdated[14673].value}
											readOnly
										/>
									</div>
									<div className='fleft width40'>
										<span className=''>Large Account Code</span>
										<input
											className='form_col width60 flright'
											type='text'
											name=''
											value={priCafDataupdated[14674].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 pad_5t over_hid'>
									<div className='fleft width60'>
										<span className=''>FA/BA/PCN ID</span>
										<input
											className='form_col width65 flright'
											type='text'
											name=''
											value={priCafDataupdated[14675].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check margin0 over_hid'>
									<div className='fleft width49'>
										<div className='check margin0 over_hid'>
											<div className='fleft width65 pad_r15_pri'>
												{' '}
												Verification by Dealer/Agent (Distributor/ Retailer): I
												confirm that the applicant has signed the form and
												documents attached in my presence and that the photo
												affixed in this form is of the applicant. I have
												verified the documents With their respective originals
												and certify the same to be true. I further confirm that
												all necessary documents are in order including that the
												signatures on the self attested photo match with the
												signature on application form and Photo on the form
												matches the one on the photo ID document.{' '}
											</div>
											<div className='flright width34'>
												{acctMngSignImageData !== null &&
												acctMngSignImageData !== '' ? (
													<img
														height='75px'
														width='75px'
														src={
															acctMngSignImageData !== null &&
															acctMngSignImageData !== ''
																? 'data:' +
																  acctMngSignImageType +
																  ';base64,' +
																  encode(acctMngSignImageData)
																: ''
														}
														alt={'Sign'}
													/>
												) : (
													''
												)}
												<div>Sign in with Seal/Stamp &nbsp; &nbsp; </div>
												<div className='in_subput_date'>
													Date
													<input
														className='form_col'
														type='text'
														name=''
														value={priCafDataupdated[14784].value}
														readOnly
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='flright width49'>
										<p className='ligthbg'>
											Declaration of VIL employee activating SIM
										</p>
										<div className='check margin0 over_hid'>
											<div className='fleft width55 pad_r15_pri'>
												{' '}
												I hereby confirm that the required documentation Such as
												POI/POA is available and that details of the Customer is
												complete and have been entered in the database.{' '}
											</div>
											<div className='flright width45'>
												<div className='activatesim'>
													<div className='check margin0 over_hid'>
														<div className='flright width70'>
															{acctMngSignImageData !== null &&
															acctMngSignImageData !== '' ? (
																<img
																	height='25px'
																	width='75px'
																	src={
																		acctMngSignImageData !== null &&
																		acctMngSignImageData !== ''
																			? 'data:' +
																			  acctMngSignImageType +
																			  ';base64,' +
																			  encode(acctMngSignImageData)
																			: ''
																	}
																	alt={'Sign'}
																/>
															) : (
																''
															)}
														</div>
														<div className='flright width70'>
															<div className='font7'>
																<div className='in_subput'>
																	Employee Name
																	<input
																		className='form_col width40px'
																		type='text'
																		name=''
																		value=''
																		readOnly
																	/>
																</div>
															</div>
															<div className='font7 in_subput'>
																<input
																	className='form_col width100per'
																	type='text'
																	name=''
																	value={priCafDataupdated[14780].value}
																	readOnly
																/>
															</div>
															<div className='font7'>
																<div className='in_subput'>
																	Designation
																	<input
																		className='form_col width40px'
																		type='text'
																		name=''
																		value=''
																		readOnly
																	/>
																</div>
															</div>
															<div className='font7'>
																<div className='in_subput_date'>
																	Date
																	<input
																		className='form_col'
																		type='text'
																		name=''
																		value={priCafDataupdated[14784].value}
																		readOnly
																	/>
																</div>
															</div>
															<div></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<p className='margin0'>
									<strong>Vodafone Idea Limited</strong> ( Formerly Idea
									Cellular Limited )<br />
									An Adity Birla Group and Vodafone partneship
									<br />
									MergerCo CIN No: L32100GJ1996PLC030976
									<br />
									<strong>Registred Office:</strong> Suman Tower, Plot no. 18,
									Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000 |
									F: +91 79 23232251
									<br />
									<strong>Circle Office Address:</strong>{' '}
								</p>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='ligthbg font8'>TERMS &amp; CONDITIONS </p>
								<p className='font10'>
									<strong>
										The "Customer Application Form" (CAF) shall mean the
										Customer information form. to which the following conditions
										("Regulatory Terms") are required to be provided/completed
										with effect from /prior to activation of any Services, and
										continue during the term of service. These are conditions
										specified by the Department of Telecommunication ("DOT)
										Ministry of Communication and Information Technology
										("MCIT"), Government of India ("Go") and are mandatory
										conditions. There shall be no deletion or deviation from the
										following Regulatory Terms. This CAF shall be read with the
										Product Specific Terms which shall form the Agreement
										between the Parties. Licensed Service Area • shall mean the
										area where Operator is permitted to offer telecommunication
										services, (i) Operator • shall mean VIL entity having
										license to operate in Circle. o 'Authority' shall mean the
										Department of Telecommunications (DOT). Telecom Regulatory
										Authority of India (TRAI), Ministry of Communication and
										Information Technology (MOCIT), Government of India (GOI)
										and includes any officer of the Authority,' "Corporate Plan"
										shall mean tariff structure devised by for Corporate
										Customers. • 'Charges' means all fees, charges/ tariffs,
										interconnection cost and rates chargeable by VIL from time
										to time for provisioning the Services to the Customer •
										'Customer' shall mean Corporate organization named in the
										CAF entering into the requisite contract comprising of CAF,
										Verification documents etc, For Internet of Things products,
										refer Intemet Of Things GTCs agreed by the customer.{' '}
									</strong>{' '}
								</p>
								<div className='over_hid'>
									<div className='fleft width49'>
										<ul className='list'>
											<li>
												It is agreed betweet "Vodafone Idea Limited (VIL)" &
												"Customer" as fallows;
											</li>
											<li>
												1. PROVISION SERVICES: VIL agrees to provide the
												services to the Customer subject to terms and condition
												of the Agreement.{' '}
											</li>
											<li style={{ display: 'inline' }}>
												2. OBLIGATIONS OF VIL: VIL shall provide services with
												reference to and subject to the telecommunication
												permitting the operations of a telephony services. The
												Customer expressly agrees that activation of the
												Services shall be Subject to verification of the
												particulars and documents submitted by the Customer
												including tele-verification. The availability and
												quality of services may be affected by factor outside
												VIL control such as physical obstruction. geographic and
												weather conditions and other cause of radio interference
												or faults in other telecommunications network to which
												network is connected. The Services may be Suspended in
												Whole or in part at any time with proper notice and in
												compliance to existing guidelines. policies and
												regulations.. however if the network fails or requires
												modifications or maintenance due to a sudden or force
												majeure event beyond control of operator. such advance
												notice may not be possible. Please note all extra
												discounting promos on special deals or on Corporate
												Plans and Closed User Group (COG). Vi<sup>TM</sup>{' '}
												Mobile Connect . Vi
												<sup>TM</sup> Live . Blackberry (BB) and other Data
												services along with respective discounts on usage will
												be activated minimum of 72hrs from date of number
												activation or the request as the case may be, The
												allotment of a number will be made by VIL in its
												absolute discretion. In the event of any provisioned SIM
												( where a Service involves a provisioning of SIM) being
												lost or stolen. VIL will replace the SIM card as soon as
												it is reasonable & practicable. subject to the recovery
												of any charges for the same. VIL reserve the right to
												apply a monthly financial limit and such other
												conditions for charges incurred by the Customer and to
												demand interim advance payment and also suspend (and/or
												disconnect access to the services if such limit is
												exceeded with proper intimation and in compliance to
												existing guidelines, policies and regulations VIL has
												the right to check the credential Of the Customer
												including the Customer financial standing & to use the
												services of any person or agency for such purposes. Any
												waiver. concession or extra time allowed or granted by
												VIL to the Customer is limited the specific
												circumstances in which it was given and the same shall
												not effect VIL's right under this agreement in any ways.
											</li>
											<li>
												3. OBLIGATIONS OF THE CUSTOMER: The Customer shall
												ensure (i) that it signs all bulk connection
												declarations and forms as are created pursuant to DOT
												guidelines on bulk connections; (ii) that it has the
												users comply with the said regulations and conclude the
												tele-verification process by calling the call centre at
												1 17 so as to enable VIL to activate his/her subscribed
												service plan: (iii) thet it shall not use or cause or
												allow others to use the Services for any improper.
												immoral or unlawful purpose including in any manner
												which may jeopardise or impair the operation Of the
												Network and/or the Services. Customer agrees to inform
												VIL immediately and confirm the same in writing if the
												Equipment and/or the SIM Card is lost. stolen or
												damaged. The Customer will remain liable for all Charges
												incurred until the SIM Card is de-activated by VIC (iv)
												to furnish correct and complete information and
												documents as required by VIL from time to time. The
												Services agreed to be provided by VIL shall always be
												subject to verification Of the Customer'S credentials
												and documents and if at any time, any information and/or
												documents furnished by the Customer Mare found incorrect
												or incomplete or suspicious. VIL shall be entitled to
												suspend/terminate the Service forthwith without any
												further notice. In case any change of address of the
												Customer or end user. the Customer agrees to inform VIL
												in writing with regard to change in address and furnish
												documents in support Of such new address. In case during
												any time during the subscription, in case the Customer
												does not inform with regard to change in his address or
												end user then VIL reserves its right to disconnect the
												Services and/or cancel the connection without any notice
												in this regard and VIL shall not be liable to the
												Customer/end user in any manner whatsoever.{' '}
											</li>
											<li>
												4. DOT LICENSING AND VERIFICATION OF SUBSCRIBE: (i) VIL
												may be requited to disclose any information pertaining
												to the Customer/Subscriber to any statutory / regulatory
												/ security authority, financial institution or bank and
												as per regulation. VIL can do so without any prior
												intimation to the Customer: (ii) Customer shall
												indemnify and keep indemnified VIL for all and any
												claims. proceedings or actions brought against VIL
												arising out of any breach of the Customer of the
												Regulatory Terms: (iii) VIL may use. process and/or
												transfer Personal Information: (iv) in connection with
												the provision Of Services; (v) to incorporate Personal
												Information into databases controlled by VIL for the
												purpose of account administration, billing and
												reconciliation. operational maintenance and support
												activities. fraud detection and prevention. and customer
												and market analysis and reporting. (vi) The Customer
												shall use the Services only for the purposes Specified
												by the Customer in the Agreement / Customer Application
												Form (or Customer Order Form or Customer Information
												Form hereinafter CAF/COF/CIF) and shall not be used for
												or connected to any telecommunication service unless
												otherwise permitted by the Governmental Authority with
												specific approvals/ licenses. The Customer understands
												and agrees that VIL shall be entitled to terminate the
												Service and collect financial penalty as levied by the
												Governmental Authority for any unauthorized use. VIL
												shall also. at its sole discretion, be entitled to
												disconnect the unauthorized circuit with immediate
												effect. The Services shall not be used for call center/
												telemarketing/ other service provider (OSP) operations
												without registration With the Department of
												Telecommunications and/ or Telecom Regulatory Authority
												of India as a call center/ OSP. The Customer who wants
												to take the services for call center/ OSP operations
												shall submit copy of call center/ OSP license or
												registration to VIL (vii)The Customer shall be allotted
												a phone number/iden- tity number by VIL which is and
												shall always remain the property of VIL. The Customer
												cannot transfer market, re•brand. resell and/or
												otherwise assign Services purchased including the phone
												number/iden tity number. Services to any Other
												person/entity unless agreed by VIL in writing. (viii)
												The Customer undertakes to comply with the applicable
												laws and regulations regarding the use Of the Service.
												including without limitation. the TRAI Act 1997. the
												Indian Telegraph Act 1885. the Indian Wireless
											</li>
										</ul>
									</div>
									<div className='fright width49'>
										<ul className='list'>
											<li>
												Telegraphy Act 1933. Information Technology Act 2000 and
												all amendments, replacements, rules and regulations made
												under such legislations from time to time. The Customer
												shall obtain any relevant consents and approvals for the
												installation and use of any equipment located at the
												Customer sites and associated with the Service and any
												permits, consents or approvals required by the
												Subscriber to use the Service. including any applicable
												other service provider permits ("Permits"). Customer
												shall use the Services in accordance with the Permits.
												Customer shall provide VIL with an up-to-date copy of
												the Customefs CIJG approval (if any) issued by the
												Department Of Telecommunications from time-to-time.
												Customer shall ensure that any network equipment (not
												provided by Services Provider) which it uses in relation
												to the Services meet the relevant International
												Telecommunication Union (ITU)/Telecommunication
												Engineering Centre (TEC) standarc'fr (ix) The Customer
												shall ensure that unauthorized or any Other Content.
												messages or communica- tians infringing copynght.
												Intellectual property right and international & domestic
												cyber laws. in any form or inconsistent with the laws of
												India. are not carried in his network by him or any
												other person using his network or dunng the provisioning
												of Semces. The Customer must take all necessary measures
												to prevent such use, The use of the Service for
												anti-national activities shall be construed as an
												offence punishable under the Indian Penal Code or other
												applicable law. The Customer must ensure that any
												service provided by Service Provider is not used for any
												unlawful purposes or any Other person using the Services
												as End User. G) Customer agrees and acknowledges that
												regulatory and law enforcement agencies Law Enforcement
												Agency (LEA), shatt have rights to access the network
												and intercept or listen or record calls being made using
												the Services and facilities provided by Service Provider
												and Customer shall provide necessary
												facilities/access/consent to Service Provider and Law
												Enforcement Agency (LEA), including but not limited to T
												RAJ. DOT. Police authorities. Reserve Bank of India.
												Enforcement Directorate and Or any other Government
												Agencies to monitor. contiol. prevent counteract
												espionage. subversive act sabotage or any other
												unlavvfulactivity. (xi) Customer understands Customer
												and its End Users use the Services at their own risk and
												consequences and Service Provider has no control over
												and accepts no responsibility whatsoever for such the
												Customer use of the Services and the transacted
												business/transmitted communication and Customer shall
												comply with all regulatory/legal and Statutory
												requirements during such use of the Services. (xii)
												Customer understands and agrees that Service Provider
												shall terminate the Service provided to the Customer
												immediately without any prior notice. upon any violation
												of these terms and conditions by the Customer and/or On
												account Of any regulatory violation by Customer or its
												End Users in using the Service or in case of any
												directive of any Law Enforcement Agency (LEA),
												Department Of Telecommunication ("DOT"), Judicial
												authority or any other Government agency. (xiii) The
												customer is required to maintain and provide updated
												list Of actual users to VIL at ad times and the customer
												agrees to give VIL the unhindered right to visit the
												customer premises and verify bonafide use of the
												servrces , (o) Customer shall not use the Services for
												any abusive, improper. immoral. offensive. illegal or
												fraudulent purposes including making calls or sending
												data that in VILS reasonable opinion is a nuisance.
												hoax, menacing, indecent. racist. obscene and/ Or
												defamatory or do anything that causes the Network to be
												impaired or damaged. Using Services to access the
												Internet: In the event that Customer or End User uses
												Services to access facilities and services not included
												in a Tariff, VIL shall not be liable under this
												Agreement for the security or confidentiality of such
												facilities or services. (xiv) No information with regard
												to an account information can be shared with any third
												party other than the duly notified authorized signatory
												of the Customer as notified by Customer to VIL and VIL
												cannot provide any account related information Outside
												India.{' '}
											</li>
											<li>
												5. CONTRACT TERMS AND COMMERCIAL TERMS: This CAF, shall
												be read With the General Terms and Conditions except
												where any master services agreement (MSA) is entered
												including (or any India specific in-country agreement
												(ICC) between the Customer and Service Provider entered
												subsequent to or pursuant to any globat level MSA
												entered between the global principals/affiliates Of the
												Customer and Service Provider) and the MSA provisions
												shall prevail over the GTC). Any Specific terms agreed
												for the mobility shall be set Out in the Part C Or a
												Product Terms and Conditions and Schedules to the GTC.
												The CAF terms will prevail over any provisions in the
												GTC/ or the MSA/ICC. which conflict With terms in CAF.
												All types of Voice. SMS and Data offerings are a part of
												the licensed services. The usage charges for these
												services would be governed by the Tariff plan opted by
												the customer.
											</li>
											<li>
												6. GOVERNING LAW: This CAF shall be subject to Indian
												Law and courts Of jurisdiction shall include Courts in
												the place where Customer has a place of business or
												where the cause of action arises or the place where the
												Services have been offered.{' '}
											</li>
											<li>
												7. MISCELLANEOUS: The Authorised Signatory signing this
												CAF on behalf of the customer hereby confirm, consent
												and authorize VIL to sell Vodafone Reload Plan or such
												other plans as may be branded by VIL for their COCP
												users. Such Plan/s will be activated upon the end user's
												request and their advance payments made directly by the
												end user either through VILS dedicated channels or
												including but not limited to Vodafone website, app.
												etc.. with no liability on the enterprise customer
												entity. Subscriber hereby declare and gives their
												consent to VIL for the collection, processing and use Of
												their personal data such as. including but not limited
												to. their name. contact details and employment related
												information for the purposes of ustng the Services and
												additional services according to VIL's Privacy Policy
												displayed on its website www.myvi.in.
											</li>
											<li>
												8. GST &amp; SEZ STATUS: The customer will be solely
												responsible for providing the correct GST number and
												'SEZ" related information against the "Place Of Supply"
												as per the GST law. S/he waives Off all the rights to
												dispute or withhold any payments in case of incorrect
												GST Numbers or incorrect SEZ certificates/ status being
												provided. which donet match the details available on the
												Government GSTN portal. The customer acknowledges that
												s/he will not be able to claim the GST tax credit under
												such circumstances and shall pay 100% invoiced amount.
												including taxes.
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page>
						<div className='section2'>
							<div className='font8'>
								<p className='ligthbg font8'>Customer Acceptance </p>
								<div className='margin0 over_hid '>
									<div className='fleft width70'>
										<p className='auth pad_t10'>
											Customer Name
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14677].value}
												readOnly
											/>
										</p>
										<p className='auth pad_t10'>
											Designation
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14678].value}
												readOnly
											/>
										</p>
										<p className='auth pad_t10'>
											Company Name
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14679].value}
												readOnly
											/>
										</p>
										<p>
											<strong>
												Note: I/We accept the responsiblates of providing the
												list of mobile nos/DID nos with user name &nbsp;
												Designation, alongwith CFA
											</strong>
										</p>
									</div>
									<div className='fleft width30 '>
										<div className='signate_box'>
											<table>
												<tbody>
													<tr>
														<td width='50%'>
															{firstCaps !== '' ? (
																<div
																	style={{
																		padding: '10px',
																		position: 'absolute',
																	}}
																	className={Signcss}
																>
																	{firstCaps + '  .  '}
																	<span className='under_line'>
																		{secondBold}
																	</span>
																</div>
															) : (
																''
															)}
														</td>
														<td width='50%'>
															{imageData !== null && imageData !== '' ? (
																<div
																	style={{ padding: '0px' }}
																	className='signate_img'
																>
																	<img
																		src={
																			imageData !== null
																				? 'data:' +
																				  imageType +
																				  ';base64,' +
																				  encode(imageData)
																				: ''
																		}
																		alt={'Company Seal'}
																	/>
																</div>
															) : (
																''
															)}
														</td>
													</tr>
													<tr>
														<td>Sign &amp; Seal</td>
														<td>
															<spa className=' pad_l_2 '>
																{' '}
																Date{' '}
																<span className='in_subput'>
																	<input
																		className='form_col width60 flright'
																		type='text'
																		name=''
																		value={priCafDataupdated[15574].value}
																		readOnly
																	/>
																</span>{' '}
															</spa>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<p className='ligthbg font8'>
									{' '}
									Fulfilment and channel details{' '}
								</p>
								<div className='auth over_hid custype pad_t5'>
									<div className='fleft width20 pad_7t'>
										Documents Verified by
									</div>
									<div className='fleft width30'>
										<input
											className='form_col fleft width90'
											type='text'
											name=''
											value={priCafDataupdated[14683].value}
											readOnly
										/>
									</div>
									<div className='fleft width20'>
										Date
										<input
											className='form_col width70'
											type='text'
											name=''
											value={priCafDataupdated[14684].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth over_hid custype pad_t5'>
									<div className='fleft width20 pad_7t'>Channel Partner</div>
									<div className='fleft width30'>
										<input
											className='form_col fleft width90'
											type='text'
											name=''
											value={priCafDataupdated[14685].value}
											readOnly
										/>
									</div>
									<div className='fleft width20'>
										Code
										<input
											className='form_col width70'
											type='text'
											name=''
											value={priCafDataupdated[14686].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth over_hid custype pad_t5'>
									<div className='fleft width20 pad_7t'>
										Vi<sup>TM</sup> Store
									</div>
									<div className='fleft width30'>
										<input
											className='form_col fleft width90'
											type='text'
											name=''
											value={priCafDataupdated[14687].value}
											readOnly
										/>
									</div>
									<div className='fleft width20'>
										Code
										<input
											className='form_col width70'
											type='text'
											name=''
											value={priCafDataupdated[14688].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth over_hid custype pad_t5'>
									<div className='fleft width20 pad_7t'>
										Vi<sup>TM</sup> No.
									</div>
									<div className='fleft width30'>
										<input
											className='form_col fleft width90'
											type='text'
											name=''
											value={priCafDataupdated[14689].value}
											readOnly
										/>
									</div>
									<div className='fleft width40'>
										Sim Card No.
										<input
											className='form_col width70'
											type='text'
											name=''
											value={priCafDataupdated[14690].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check margin0 over_hid'>
									<div className='fleft width70'>
										<div className='pad_r15 pad_t10'>
											I/We hereby undertake and confirm that I/We have seen the
											customer and certify that (a) the customer enrolment form
											has been duly filled by the applicant, (b) the photograph
											submitted has been matched with the applicant, (c )the
											form has been personally signed by the applicant in my
											presence, (d) proof of identity & address has been
											collected, (e) the original proof of identity & address
											has been matched and verified with the self-attested
											documents submitted by the applicant. Details on the Vi{' '}
											<sup>TM</sup> CAF match with these documents. In case of
											outstation customer, I/ We hereby confirm that I/We
											tele-verified the particulars furnished by the applicant
											with local reference and recorded its correctness. Name of
											Local Reference contacted ( for Outstation subscribers).
											<span className='in_subput'>
												<input
													className='form_col width150px'
													type='text'
													name=''
													value=''
													readOnly
												/>
											</span>{' '}
										</div>
									</div>
									<div className='fleft width30 '>
										<div className='signate_box'>
											<table>
												<tbody>
													<tr>
														<td>
															{acctMngSignImageData !== null &&
															acctMngSignImageData !== '' ? (
																<div
																	style={{ padding: '0px' }}
																	className='signate_img'
																>
																	<img
																		height='25px'
																		width='100px'
																		src={
																			acctMngSignImageData !== null ||
																			acctMngSignImageData !== ''
																				? 'data:' +
																				  acctMngSignImageType +
																				  ';base64,' +
																				  encode(acctMngSignImageData)
																				: ''
																		}
																		alt={'Signature of Account Manager'}
																	/>
																</div>
															) : (
																''
															)}
														</td>
													</tr>
													<tr>
														<td>Sign &amp; Seal</td>
														<td>
															<spa className=' pad_l_2 '>
																{' '}
																Date{' '}
																<span className='in_subput'>
																	<input
																		className='form_col width60 flright'
																		type='text'
																		name=''
																		value={priCafDataupdated[15574].value}
																		readOnly
																	/>
																</span>{' '}
															</spa>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width40'>
										Enterprise Code
										<input
											className='form_col width65'
											type='text'
											name=''
											value={priCafDataupdated[14692].value}
											readOnly
										/>
									</div>
									<div className='fleft width35'>
										Account Manager Name
										<input
											className='form_col width45'
											type='text'
											name=''
											value={priCafDataupdated[14693].value}
											readOnly
										/>
									</div>
									<div className='fleft width25'>
										Code
										<input
											className='form_col width70 flright'
											type='text'
											name=''
											value={priCafDataupdated[14694].value}
											readOnly
										/>
									</div>
								</div>
								<br />
								<br />
								<br />
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='over_hid'>
									<img src={VodafoneImg} />
								</p>
								<p className='enroll_form'> Tariff Enrolment Form</p>
								<p className='sip_central'>SIP Central </p>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width70'>
										<div className='fleft width40'>
											<strong>SDCA name</strong> (where service is required):
										</div>
										<input
											className='form_col width50'
											type='text'
											name=''
											value={priCafDataupdated[14695].value}
											readOnly
										/>
									</div>
									<div className='fleft width25'>
										<strong>STD Code</strong>
										<input
											className='form_col width65'
											type='text'
											name=''
											value={priCafDataupdated[15048].value}
											readOnly
										/>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width70'>
										<div className='fleft width40'>
											<strong>Proof of Billing Address attached:</strong>
										</div>
										<input
											className='form_col width50'
											type='text'
											name=''
											value={priCafDataupdated[14696].value}
											readOnly
										/>
									</div>
								</div>
								<p>
									<strong>Installation Address:</strong>
								</p>
								<div className='pad_l50'>
									<p className='auth'>
										Contact Person Name
										<input
											className='form_col width45'
											type='text'
											name=''
											value={priCafDataupdated[14698].value}
											readOnly
										/>
									</p>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width40'>
											Designation
											<input
												className='form_col width65'
												type='text'
												name=''
												value={priCafDataupdated[14699].value}
												readOnly
											/>
										</div>
										<div className='fleft width25'>
											Department
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14700].value}
												readOnly
											/>
										</div>
									</div>
									<p className='auth'>
										Address
										<input
											className='form_col width55'
											type='text'
											name=''
											value={priCafDataupdated[14701].value}
											readOnly
										/>
									</p>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width40'>
											City/ Village/ Post Office:
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14702].value}
												readOnly
											/>
										</div>
										<div className='fleft width25'>
											Pin:
											<input
												className='form_col width70'
												type='text'
												name=''
												value={priCafDataupdated[14703].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width40'>
											Telephone Number:
											<input
												className='form_col width58'
												type='text'
												name=''
												value={priCafDataupdated[14704].value}
												readOnly
											/>
										</div>
										<div className='fleft width25'>
											Fax:
											<input
												className='form_col width70'
												type='text'
												name=''
												value={priCafDataupdated[14705].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width36'>
											Contact email id:
											<input
												className='form_col width58'
												type='text'
												name=''
												value={priCafDataupdated[14706].value}
												readOnly
											/>
										</div>
										<div className='fleft width40'>
											Mobile No./ Alt No.:
											<input
												className='form_col width40'
												type='text'
												name=''
												value={priCafDataupdated[14707].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<p>
									<strong>GST Customer Information:</strong>
								</p>
								<div className='pad_l50'>
									<div className='check margin0 over_hid'>
										<div className='fleft width10'>
											GSTIN
											{priCafDataupdated[14622].value === 'GSTIN' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width10'>
											UIN
											{priCafDataupdated[14622].value === 'UIN' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											GST_ISD
											{priCafDataupdated[14622].value === 'GST_ISD' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width12 pad_t2'>Customer Type</div>
										<div className='fleft width12'>
											{' '}
											Normal
											{priCafDataupdated[14623].value === 'Normal' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											SEZ
											{priCafDataupdated[14623].value === 'SEZ' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Diplomat
											{priCafDataupdated[14623].value === 'Diplomat' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Embassy
											{priCafDataupdated[14623].value === 'Embassy' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Consulate
											{priCafDataupdated[14623].value === 'Consulate' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Special Agency
											{priCafDataupdated[14623].value === 'Special Agency' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Persons/ className of Persons
											{priCafDataupdated[14623].value ===
											'Persons/ className of Persons' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<p className='auth'>
										GSTIN/ UIN/ GST_ID No
										<input
											className='form_col wid30'
											type='text'
											name=''
											value={priCafDataupdated[14624].value}
											readOnly
										/>
									</p>
									<p className='auth'>
										GST Regd. Address (in Installation State)
										<input
											className='form_col wid50'
											type='text'
											name=''
											value={priCafDataupdated[14712].value}
											readOnly
										/>
									</p>
									<div className='check over_hid typecheck gstcheck gstone'>
										<div className='fleft width12 pad_t2'>
											GST Customer Dept
										</div>
										<div className='fleft width12'>
											{' '}
											IT
											{priCafDataupdated[14713].value === 'IT' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Tech
											{priCafDataupdated[14713].value === 'Tech' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Customer Service
											{priCafDataupdated[14713].value === 'Customer Service' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Legal
											{priCafDataupdated[14713].value === 'Legal' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Finance
											{priCafDataupdated[14713].value === 'Finance' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											Purchase
											{priCafDataupdated[14713].value === 'Purchase' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{' '}
											SCM
											{priCafDataupdated[14713].value === 'SCM' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width12'>
											{priCafDataupdated[14713].value === 'Delivery' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Delivery{' '}
										</div>
										<div className='fleft width20 pad_t2'>
											{priCafDataupdated[14713].value === 'Others' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Others
											<input
												className='form_col oth_gst'
												type='text'
												name=''
												value={priCafDataupdated[15038].value}
												readOnly
											/>
										</div>
									</div>
									<p className='auth'>
										GST Supporting Document (GST Certificate, SEZ Certificate,
										Diplomat certificate)
										<input
											className='form_col width40'
											type='text'
											name=''
											value={priCafDataupdated[14714].value}
											readOnly
										/>
									</p>
								</div>
								<p>
									<strong>Service Requirements:</strong>
								</p>
								<div className='pad_l50'>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft pad_t2 pad_r50'>Service Flavor:</div>
										<div className='fleft width15'>
											{' '}
											Managed
											{priCafDataupdated[225250].value === 'Managed' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													disabled
												/>
											)}{' '}
										</div>
										<div className='fleft width12'>
											{' '}
											Unmanaged
											{priCafDataupdated[225250].value === 'Unmanaged' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													disabled
												/>
											)}{' '}
										</div>
									</div>

									<div className='check margin0 over_hid typecheck'>
										<div className='fleft pad_t2 pad_r50'></div>
										<div
											className='pad_t2'
											style={{ width: '100%', paddingLeft: '110px' }}
										>
											{' '}
											(If Managed Flavour is selected above, select any 1 from
											the below 2 options)
										</div>
									</div>
									<div
										className='check margin0 over_hid typecheck'
										style={{ paddingLeft: '60px' }}
									>
										<div className='fleft pad_t2 pad_r50'></div>
										<div className='fleft width15'>
											{' '}
											Standard
											{priCafDataupdated[225251].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													disabled
												/>
											)}{' '}
										</div>
										<div className='fleft width12'>
											{' '}
											Enhanced
											{priCafDataupdated[225251].value === 'Enhanced' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													disabled
												/>
											)}{' '}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft pad_t2 pad_r50'>Session Type:</div>
										<div className='fleft width12'>
											{' '}
											Voice
											{priCafDataupdated[14716].value === 'Voice' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>
											Concurrent number of Sessions:{' '}
										</div>
										<div className='fleft width50'>
											Voice
											<input
												className='form_col width10'
												type='text'
												name=''
												value={priCafDataupdated[14717].value}
												readOnly
											/>
											<span className='fnt7 pad_l10'>
												(Session granularity = 1)
											</span>{' '}
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>Codec </div>
										<div className='fleft width50'>
											Voice
											<input
												className='form_col width10'
												type='text'
												name=''
												value={priCafDataupdated[14718].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>Pilot Number: </div>
										<div className='fleft width10'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14719].value}
												readOnly
											/>
											<div className='fnt6 textcenter'>(STD code) </div>
										</div>
										<div className='fleft width20 pad_l50'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14720].value}
												readOnly
											/>
											<div className='fnt6 textcenter'>(Pilot Number) </div>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>
											DID Number Block Quantity 1:{' '}
										</div>
										<div className='fleft width10'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14721].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r15 pad_t3'>
											DID Numbers Required 1 From
										</div>
										<div className='fleft width20'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14723].value}
												readOnly
											/>
										</div>
										<div className='fleft width5 pad_t3'>to &nbsp; </div>
										<div className='fleft width20'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14724].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>
											DID Number Block Quantity 2:{' '}
										</div>
										<div className='fleft width10'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14725].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r15 pad_t3'>
											DID Numbers Required 2:From{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14727].value}
												readOnly
											/>
										</div>
										<div className='fleft width5 pad_t3'>to &nbsp; </div>
										<div className='fleft width20'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14728].value}
												readOnly
											/>
										</div>
										<div className='fleft fnt7 pad_t3 pad_l10'>
											(use additional sheets if required)
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft pad_r50'>Number Reservation ID: </div>
										<div className='fleft width10'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14729].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className='pad_l50'>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>Billing Type: </div>
										<div className='fleft width20'>
											{' '}
											DID Wise
											{priCafDataupdated[14730].value === 'DID Wise' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											Pilot Wise
											{priCafDataupdated[14730].value === 'Pilot Wise' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>
											Facilities Required:{' '}
										</div>
										<div className='fleft width20'>
											{' '}
											Outgoing Local Only
											{priCafDataupdated[14731].value
												.split('|')
												.indexOf('Outgoing Local Only') >= 0 ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											STD Calling
											{priCafDataupdated[14731].value
												.split('|')
												.indexOf('STD Calling') >= 0 ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											ISD Calling
											{priCafDataupdated[14731].value
												.split('|')
												.indexOf('ISD Calling') >= 0 ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											Incoming Calls only
											{priCafDataupdated[14731].value
												.split('|')
												.indexOf('Incoming Calls only') >= 0 ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>
											Purpose of SIP Central:{' '}
										</div>
										<div className='fleft width20'>
											{' '}
											Office Calling
											{priCafDataupdated[313688].value === 'Office Calling' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											Voice Aggregation
											{priCafDataupdated[313688].value ===
											'Voice Aggregation' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											Auto Dialer Call (with IVR)
											{priCafDataupdated[313688].value ===
											'Auto Dialer Call (with IVR)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck gstcheck pad_5t'>
										<div className='fleft width20 pad_t2'></div>
										<div className='fleft width22'>
											{' '}
											Auto Dialer Call (with Agent)
											{priCafDataupdated[313688].value ===
											'Auto Dialer Call (with Agent)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20'>
											{' '}
											BPO / KPO
											{priCafDataupdated[313688].value === 'BPO / KPO' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width20 pad_t2'>
											Others
											{priCafDataupdated[313688].value === 'Others' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}{' '}
											<span className='in_subput'>
												<input
													className='form_col oth_gst'
													type='text'
													name=''
													value={priCafDataupdated[313689].value}
													readOnly
												/>
											</span>
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>CUG: </div>
										<div className='fleft width15'>
											{' '}
											Yes
											{priCafDataupdated[14732].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft'>
											{' '}
											No
											{priCafDataupdated[14732].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											<span className='fnt7 pad_t3'>
												(SIP Central-2-SIP Central)
											</span>{' '}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>UCC Preference: </div>
										<div className='fleft width15'>
											{' '}
											Yes
											{priCafDataupdated[14733].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width15'>
											{' '}
											No
											{priCafDataupdated[14733].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>DID Operator: </div>
										<div className='fleft width15'>
											{' '}
											Yes
											{priCafDataupdated[14734].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width15'>
											{' '}
											No
											{priCafDataupdated[14734].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft pad_t3 fnt7'>
											{' '}
											(If Yes, please execute DID Operator Agreement with VIL
											and submit a copy of the VNO license){' '}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width25 pad_t2'>
											Existing VIL L2/L3 VPN customer:
										</div>
										<div className='fleft width15'>
											{' '}
											Yes
											{priCafDataupdated[14735].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width15'>
											{' '}
											No
											{priCafDataupdated[14735].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width20'>Circuit ID: </div>
										<div className='fleft width10'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14736].value}
												readOnly
											/>
										</div>
									</div>
									<p>
										<strong>CPE Details Primary</strong>
									</p>

									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width25 pad_t2'>
											CPE Provided by:{' '}
										</div>
										<div className='fleft width15'>
											{' '}
											VIL
											{priCafDataupdated[306989].value === 'VIL' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width15'>
											{' '}
											Customer
											{priCafDataupdated[306989].value === 'Customer' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width25 pad_t2'>
											If Provided by VIL:{' '}
										</div>
										<div className='fleft width15'>
											{' '}
											Rent
											{priCafDataupdated[306990].value === 'Rent' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
										<div className='fleft width15'>
											{' '}
											Sale
											{priCafDataupdated[306990].value === 'Sale' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<div class='wifitable width1000'>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Type
														<br />
														(Standard/Other)
													</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
													<td>Quantity</td>
													<td>Equipment ID</td>
													<td>Equipment Name</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[306991].value} &nbsp;</td>
													<td>{priCafDataupdated[306992].value}&nbsp; </td>
													<td>{priCafDataupdated[306993].value}&nbsp; </td>
													<td>{priCafDataupdated[306994].value}&nbsp; </td>
													<td>{priCafDataupdated[306995].value} &nbsp;</td>
													<td>{priCafDataupdated[306996].value} &nbsp;</td>
													<td>{priCafDataupdated[306997].value}&nbsp; </td>
													<td>{priCafDataupdated[306998].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307000].value} &nbsp;</td>
													<td>{priCafDataupdated[307001].value}&nbsp; </td>
													<td>{priCafDataupdated[307002].value}&nbsp; </td>
													<td>{priCafDataupdated[307003].value}&nbsp; </td>
													<td>{priCafDataupdated[307004].value} &nbsp;</td>
													<td>{priCafDataupdated[307005].value} &nbsp;</td>
													<td>{priCafDataupdated[307006].value}&nbsp; </td>
													<td>{priCafDataupdated[307007].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307008].value} &nbsp;</td>
													<td>{priCafDataupdated[307009].value}&nbsp; </td>
													<td>{priCafDataupdated[307010].value}&nbsp; </td>
													<td>{priCafDataupdated[307011].value}&nbsp; </td>
													<td>{priCafDataupdated[307012].value} &nbsp;</td>
													<td> {priCafDataupdated[307013].value}&nbsp;</td>
													<td>{priCafDataupdated[307014].value}&nbsp; </td>
													<td>{priCafDataupdated[307015].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>
										<p>If provided by Customer: (fill below details)</p>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Equipment
														<br />
														Type
													</td>
													<td>Model</td>
													<td>Vendor</td>
													<td>
														Port
														<br />
														Number
													</td>
													<td>
														Serial
														<br />
														Number
													</td>
													<td>
														Equipment
														<br />
														Name
													</td>
													<td>
														Equipment
														<br />
														ID
													</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[307017].value} &nbsp;</td>
													<td>{priCafDataupdated[307018].value}&nbsp; </td>
													<td>{priCafDataupdated[307019].value}&nbsp; </td>
													<td>{priCafDataupdated[307020].value}&nbsp; </td>
													<td> {priCafDataupdated[307021].value}&nbsp;</td>
													<td>{priCafDataupdated[307022].value} &nbsp;</td>
													<td>{priCafDataupdated[307023].value}&nbsp; </td>
													<td>{priCafDataupdated[307024].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307026].value} &nbsp;</td>
													<td>{priCafDataupdated[307027].value}&nbsp; </td>
													<td>{priCafDataupdated[307028].value}&nbsp; </td>
													<td>{priCafDataupdated[307029].value}&nbsp; </td>
													<td>{priCafDataupdated[307030].value} &nbsp;</td>
													<td>{priCafDataupdated[307031].value} &nbsp;</td>
													<td>{priCafDataupdated[307032].value}&nbsp; </td>
													<td>{priCafDataupdated[307033].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307034].value} &nbsp;</td>
													<td>{priCafDataupdated[307035].value}&nbsp; </td>
													<td>{priCafDataupdated[307036].value}&nbsp; </td>
													<td>{priCafDataupdated[307037].value}&nbsp; </td>
													<td>{priCafDataupdated[307038].value} &nbsp;</td>
													<td>{priCafDataupdated[307039].value} &nbsp;</td>
													<td>{priCafDataupdated[307040].value}&nbsp; </td>
													<td>{priCafDataupdated[307041].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>

										<p>
											<strong>CPE Details Secondary</strong>
										</p>

										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>CPE Provided by: </div>
											<div class='fleft width15'>
												{' '}
												VIL
												{priCafDataupdated[307043].value === 'VIL' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Customer
												{priCafDataupdated[307043].value === 'Customer' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
										</div>
										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>
												If provided by VIL:{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Rent
												{priCafDataupdated[307044].value === 'Rent' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width6'>
												{' '}
												Sale
												{priCafDataupdated[307044].value === 'Sale' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width35 pad_5t fnt8'>
												(select any 1 option and fill below details)
											</div>
										</div>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Type
														<br />
														(Standard/Other)
													</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
													<td>Quantity</td>
													<td>Equipment ID</td>
													<td>Equipment Name</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[307045].value} &nbsp;</td>
													<td>{priCafDataupdated[307046].value}&nbsp; </td>
													<td>{priCafDataupdated[307047].value}&nbsp; </td>
													<td>{priCafDataupdated[307048].value}&nbsp; </td>
													<td>{priCafDataupdated[307049].value} &nbsp;</td>
													<td>{priCafDataupdated[307050].value} &nbsp;</td>
													<td>{priCafDataupdated[307051].value}&nbsp; </td>
													<td>{priCafDataupdated[307052].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307054].value} &nbsp;</td>
													<td>{priCafDataupdated[307055].value}&nbsp; </td>
													<td>{priCafDataupdated[307056].value}&nbsp; </td>
													<td>{priCafDataupdated[307057].value}&nbsp; </td>
													<td>{priCafDataupdated[307058].value} &nbsp;</td>
													<td>{priCafDataupdated[307059].value} &nbsp;</td>
													<td>{priCafDataupdated[307060].value}&nbsp; </td>
													<td>{priCafDataupdated[307061].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307062].value} &nbsp;</td>
													<td>{priCafDataupdated[307063].value}&nbsp; </td>
													<td>{priCafDataupdated[307064].value}&nbsp; </td>
													<td>{priCafDataupdated[307065].value}&nbsp; </td>
													<td>{priCafDataupdated[307066].value} &nbsp;</td>
													<td>{priCafDataupdated[307067].value} &nbsp;</td>
													<td>{priCafDataupdated[307068].value}&nbsp; </td>
													<td>{priCafDataupdated[307069].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>
										<p>If provided by Customer: (fill below details)</p>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Equipment
														<br />
														Type
													</td>
													<td>Model</td>
													<td>Vendor</td>
													<td>
														Port
														<br />
														Number
													</td>
													<td>
														Serial
														<br />
														Number
													</td>
													<td>
														Equipment
														<br />
														Name
													</td>
													<td>
														Equipment
														<br />
														ID
													</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[307071].value} &nbsp;</td>
													<td>{priCafDataupdated[307072].value}&nbsp; </td>
													<td>{priCafDataupdated[307073].value}&nbsp; </td>
													<td>{priCafDataupdated[307074].value}&nbsp; </td>
													<td>{priCafDataupdated[307075].value} &nbsp;</td>
													<td>{priCafDataupdated[307076].value} &nbsp;</td>
													<td>{priCafDataupdated[307077].value}&nbsp; </td>
													<td>{priCafDataupdated[307078].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307080].value} &nbsp;</td>
													<td>{priCafDataupdated[307081].value}&nbsp; </td>
													<td>{priCafDataupdated[307082].value}&nbsp; </td>
													<td>{priCafDataupdated[307083].value}&nbsp; </td>
													<td>{priCafDataupdated[307084].value} &nbsp;</td>
													<td>{priCafDataupdated[307085].value} &nbsp;</td>
													<td>{priCafDataupdated[307086].value}&nbsp; </td>
													<td>{priCafDataupdated[307087].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307088].value} &nbsp;</td>
													<td>{priCafDataupdated[307089].value}&nbsp; </td>
													<td>{priCafDataupdated[307090].value}&nbsp; </td>
													<td>{priCafDataupdated[307091].value}&nbsp; </td>
													<td>{priCafDataupdated[307092].value} &nbsp;</td>
													<td>{priCafDataupdated[307093].value} &nbsp;</td>
													<td>{priCafDataupdated[307094].value}&nbsp; </td>
													<td>{priCafDataupdated[307095].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>

										<p>
											<strong>Gateway/ Switch Details Primary</strong>
										</p>

										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>
												Gateway/ Switch Provided by:{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												VIL
												{priCafDataupdated[307097].value === 'VIL' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Customer
												{priCafDataupdated[307097].value === 'Customer' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
										</div>
										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>
												If provided by VIL:{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Rent
												{priCafDataupdated[307098].value === 'Rent' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width6'>
												{' '}
												Sale
												{priCafDataupdated[307098].value === 'Sale' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width35 pad_5t fnt8'>
												(select any 1 option and fill below details)
											</div>
										</div>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Type
														<br />
														(Standard/Other)
													</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
													<td>Quantity</td>
													<td>Equipment ID</td>
													<td>Equipment Name</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td> {priCafDataupdated[307099].value}&nbsp;</td>
													<td>{priCafDataupdated[307100].value}&nbsp; </td>
													<td>{priCafDataupdated[307101].value}&nbsp; </td>
													<td>{priCafDataupdated[307102].value}&nbsp; </td>
													<td>{priCafDataupdated[307103].value} &nbsp;</td>
													<td>{priCafDataupdated[307104].value} &nbsp;</td>
													<td>{priCafDataupdated[307105].value}&nbsp; </td>
													<td>{priCafDataupdated[307106].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307108].value} &nbsp;</td>
													<td>{priCafDataupdated[307109].value}&nbsp; </td>
													<td>{priCafDataupdated[307110].value}&nbsp; </td>
													<td>{priCafDataupdated[307111].value}&nbsp; </td>
													<td>{priCafDataupdated[307112].value} &nbsp;</td>
													<td>{priCafDataupdated[307113].value} &nbsp;</td>
													<td>{priCafDataupdated[307114].value}&nbsp; </td>
													<td>{priCafDataupdated[307115].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307116].value} &nbsp;</td>
													<td>{priCafDataupdated[307117].value}&nbsp; </td>
													<td>{priCafDataupdated[307118].value}&nbsp; </td>
													<td>{priCafDataupdated[307119].value}&nbsp; </td>
													<td>{priCafDataupdated[307120].value} &nbsp;</td>
													<td>{priCafDataupdated[307121].value} &nbsp;</td>
													<td>{priCafDataupdated[307122].value}&nbsp; </td>
													<td>{priCafDataupdated[307123].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>
										<p>If provided by Customer: (fill below details)</p>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Equipment
														<br />
														Type
													</td>
													<td>Model</td>
													<td>Vendor</td>
													<td>
														Port
														<br />
														Number
													</td>
													<td>
														Serial
														<br />
														Number
													</td>
													<td>
														Equipment
														<br />
														Name
													</td>
													<td>
														Equipment
														<br />
														ID
													</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td> {priCafDataupdated[307125].value}&nbsp;</td>
													<td>{priCafDataupdated[307126].value}&nbsp; </td>
													<td>{priCafDataupdated[307127].value}&nbsp; </td>
													<td>{priCafDataupdated[307128].value}&nbsp; </td>
													<td>{priCafDataupdated[307129].value} &nbsp;</td>
													<td>{priCafDataupdated[307130].value} &nbsp;</td>
													<td>{priCafDataupdated[307131].value}&nbsp; </td>
													<td>{priCafDataupdated[307132].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307134].value} &nbsp;</td>
													<td>{priCafDataupdated[307135].value}&nbsp; </td>
													<td>{priCafDataupdated[307136].value}&nbsp; </td>
													<td>{priCafDataupdated[307137].value}&nbsp; </td>
													<td>{priCafDataupdated[307138].value} &nbsp;</td>
													<td>{priCafDataupdated[307139].value} &nbsp;</td>
													<td>{priCafDataupdated[307140].value}&nbsp; </td>
													<td>{priCafDataupdated[307141].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307142].value} &nbsp;</td>
													<td>{priCafDataupdated[307143].value}&nbsp; </td>
													<td>{priCafDataupdated[307144].value}&nbsp; </td>
													<td>{priCafDataupdated[307145].value}&nbsp; </td>
													<td>{priCafDataupdated[307146].value} &nbsp;</td>
													<td>{priCafDataupdated[307147].value} &nbsp;</td>
													<td>{priCafDataupdated[307148].value}&nbsp; </td>
													<td>{priCafDataupdated[307149].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>

										<p>
											<strong>Gateway/ Switch Details Secondary</strong>
										</p>

										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>
												Gateway/ Switch Provided by:{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												VIL
												{priCafDataupdated[307151].value === 'VIL' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Customer
												{priCafDataupdated[307151].value === 'Customer' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
										</div>
										<div class='check margin0 over_hid typecheck'>
											<div class='fleft width25 pad_t2'>
												If provided by VIL:{' '}
											</div>
											<div class='fleft width15'>
												{' '}
												Rent
												{priCafDataupdated[307152].value === 'Rent' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width6'>
												{' '}
												Sale
												{priCafDataupdated[307152].value === 'Sale' ? (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}{' '}
											</div>
											<div class='fleft width35 pad_5t fnt8'>
												(select any 1 option and fill below details)
											</div>
										</div>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Type
														<br />
														(Standard/Other)
													</td>
													<td>Make</td>
													<td>Model</td>
													<td>Version</td>
													<td>Quantity</td>
													<td>Equipment ID</td>
													<td>Equipment Name</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[307153].value} &nbsp;</td>
													<td>{priCafDataupdated[307154].value}&nbsp; </td>
													<td>{priCafDataupdated[307155].value}&nbsp; </td>
													<td>{priCafDataupdated[307156].value}&nbsp; </td>
													<td>{priCafDataupdated[307157].value} &nbsp;</td>
													<td>{priCafDataupdated[307158].value} &nbsp;</td>
													<td>{priCafDataupdated[307159].value}&nbsp; </td>
													<td>{priCafDataupdated[307160].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307162].value} &nbsp;</td>
													<td>{priCafDataupdated[307163].value}&nbsp; </td>
													<td>{priCafDataupdated[307164].value}&nbsp; </td>
													<td>{priCafDataupdated[307165].value}&nbsp; </td>
													<td>{priCafDataupdated[307166].value} &nbsp;</td>
													<td>{priCafDataupdated[307167].value} &nbsp;</td>
													<td>{priCafDataupdated[307168].value}&nbsp; </td>
													<td>{priCafDataupdated[307169].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307170].value} &nbsp;</td>
													<td>{priCafDataupdated[307171].value}&nbsp; </td>
													<td>{priCafDataupdated[307172].value}&nbsp; </td>
													<td>{priCafDataupdated[307173].value}&nbsp; </td>
													<td>{priCafDataupdated[307174].value} &nbsp;</td>
													<td>{priCafDataupdated[307175].value} &nbsp;</td>
													<td>{priCafDataupdated[307176].value}&nbsp; </td>
													<td>{priCafDataupdated[307177].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>
										<p>If provided by Customer: (fill below details)</p>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Sr No</td>
													<td>
														Equipment
														<br />
														Type
													</td>
													<td>Model</td>
													<td>Vendor</td>
													<td>
														Port
														<br />
														Number
													</td>
													<td>
														Serial
														<br />
														Number
													</td>
													<td>
														Equipment
														<br />
														Name
													</td>
													<td>
														Equipment
														<br />
														ID
													</td>
													<td>Description</td>
												</tr>
												<tr>
													<td> 1 </td>
													<td>{priCafDataupdated[307179].value} &nbsp;</td>
													<td>{priCafDataupdated[307180].value}&nbsp; </td>
													<td>{priCafDataupdated[307181].value}&nbsp; </td>
													<td>{priCafDataupdated[307182].value}&nbsp; </td>
													<td>{priCafDataupdated[307183].value} &nbsp;</td>
													<td>{priCafDataupdated[307184].value} &nbsp;</td>
													<td>{priCafDataupdated[307185].value}&nbsp; </td>
													<td>{priCafDataupdated[307186].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 2 </td>
													<td>{priCafDataupdated[307188].value} &nbsp;</td>
													<td>{priCafDataupdated[307189].value}&nbsp; </td>
													<td>{priCafDataupdated[307190].value}&nbsp; </td>
													<td>{priCafDataupdated[307191].value}&nbsp; </td>
													<td>{priCafDataupdated[307192].value} &nbsp;</td>
													<td>{priCafDataupdated[307193].value} &nbsp;</td>
													<td>{priCafDataupdated[307194].value}&nbsp; </td>
													<td>{priCafDataupdated[307195].value}&nbsp; </td>
												</tr>
												<tr>
													<td> 3 </td>
													<td>{priCafDataupdated[307196].value} &nbsp;</td>
													<td>{priCafDataupdated[307197].value}&nbsp; </td>
													<td>{priCafDataupdated[307198].value}&nbsp; </td>
													<td>{priCafDataupdated[307199].value}&nbsp; </td>
													<td>{priCafDataupdated[307200].value} &nbsp;</td>
													<td>{priCafDataupdated[307201].value} &nbsp;</td>
													<td>{priCafDataupdated[307202].value}&nbsp; </td>
													<td>{priCafDataupdated[307203].value}&nbsp; </td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width33'>
										Private and Confidential – Vi<sup>TM</sup> C2{' '}
									</div>
									<div className='fleft width33 textcenter'>
										– Vi<sup>TM</sup> SIP Central Services{' '}
									</div>
									<div className='fleft width33 text_r'>
										© Vodafone India Limited
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='over_hid'>
									<img src={VodafoneImg} />
								</p>
								<div className='pad_l50'>
									<p>
										<strong>Last Mile Details:</strong>
									</p>
									<div className='check margin0 over_hid typecheck'>
										<div className='fleft width20 pad_t2'>
											Primary LM Media:{' '}
										</div>
										<div className='fleft width15'>
											{' '}
											Fiber
											{priCafDataupdated[14755].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
										</div>
									</div>
									<p>Other Operator Contact Details:</p>
									<div className='wifitable width70'>
										<table
											width='100%'
											border='0'
											cellspacing='0'
											cellpadding='0'
										>
											<tbody>
												<tr>
													<td>Department</td>
													<td>First Name</td>
													<td>Last Name</td>
													<td>Contact Number</td>
													<td>Email</td>
												</tr>
												<tr>
													<td>{priCafDataupdated[14757].value}</td>
													<td>{priCafDataupdated[14758].value}</td>
													<td>{priCafDataupdated[14759].value}</td>
													<td>{priCafDataupdated[14760].value}</td>
													<td>{priCafDataupdated[14761].value}</td>
												</tr>
												<tr>
													<td>{priCafDataupdated[14762].value}</td>
													<td>{priCafDataupdated[14763].value}</td>
													<td>{priCafDataupdated[14764].value}</td>
													<td>{priCafDataupdated[14765].value}</td>
													<td>{priCafDataupdated[14766].value}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p>
										<strong>Commercials:</strong>
									</p>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>Tariff Plan Name: </div>
										<div className='fleft width50'>
											<input
												className='form_col width90'
												type='text'
												name=''
												value={priCafDataupdated[14637].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>Rental per User: </div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[15045].value}
												readOnly
											/>
										</div>
									</div>
									<p>FCV Multiplier</p>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											(FCV Value/ Rental Value:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[15047].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>Security Deposit: </div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14769].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											Premium Number Charges (one time):{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14770].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>User rental (monthly): </div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14771].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											Installation Charges (one time):{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14772].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											Service Registration Charges (one time):
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14773].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											CPE charges (one time) if any:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14774].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											CPE rental (monthly) if any:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14775].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											Bandwidth rental (monthly) if any:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14776].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											CUG rental (monthly) if any:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14777].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>Total Amount Payable: </div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[14778].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											Total Amount Paid with CAF:{' '}
										</div>
										<div className='fleft width50'>
											{' '}
											Rs.
											<input
												className='form_col width50'
												type='text'
												name=''
												value={priCafDataupdated[15109].value}
												readOnly
											/>
										</div>
									</div>
									<div className='decl'>
										<div className='dectext'>
											{' '}
											<strong>Declaration:</strong>
											<br />
											We have read and understood the General Terms and
											Conditions mentioned overleaf and unconditionally accept
											them as binding on us. We have understood all the rates,
											tariffs and other related conditions at which the Services
											will be provider inside India as applicable on this date
											as amended from time to time. We undertake to pay all the
											charges raised on account of the Services availed. We
											further declare and undertake that the above provided by
											us is true and correct in all aspect.
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Customer Account Code:</strong>{' '}
										</div>
										<div className='fleft width50'>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14779].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Name of the Account Manager: </strong>{' '}
										</div>
										<div className='fleft width50'>
											<input
												className='form_col width80'
												type='text'
												name=''
												value={priCafDataupdated[14780].value}
												readOnly
											/>
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Signature of the Account Manager:</strong>{' '}
										</div>
										<div className='fleft width50'>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<img
													height='50px'
													width='100px'
													src={
														acctMngSignImageData !== null &&
														acctMngSignImageData !== ''
															? 'data:' +
															  acctMngSignImageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Sign'}
												/>
											) : (
												''
											)}
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Signature of the Customer:</strong>{' '}
										</div>
										<div className='fleft width50'>
											{firstCaps !== '' ? (
												<div
													style={{ padding: '10px', position: 'absolute' }}
													className={Signcss}
												>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
										</div>
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Seal:</strong>{' '}
										</div>
										{imageData !== null && imageData !== '' ? (
											<div style={{ padding: '10px' }} className='signate_img'>
												<img
													src={
														imageData != null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(imageData)
															: ''
													}
													alt={'Company Seal'}
												/>
											</div>
										) : (
											''
										)}
									</div>
									<div className='auth margin0 over_hid pad_5t'>
										<div className='fleft width30'>
											<strong>Date:</strong>{' '}
										</div>
									</div>
								</div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width33'>
										Private and Confidential – Vi<sup>TM</sup> C2{' '}
									</div>
									<div className='fleft width33 textcenter'>
										– Vi<sup>TM</sup> SIP Central Services{' '}
									</div>
									<div className='fleft width33 text_r'>
										© Vodafone India Limited
									</div>
								</div>
							</div>
						</div>
					</page>
					{!!queueData &&
					getVersionTerms(queueData?.activity_datetime_created || '') ===
						TERMS_VERSION.NEW_TERMS ? (
						<TermsConditionNew
							isCrf={false}
							firstCaps={firstCaps}
							Signcss={Signcss}
							secondBold={secondBold}
							imageData={imageData}
							imageType={imageType}
							isFlv={true}
							CustomComponent={CustomComponent}
						/>
					) : (
						<page size='A4'>
							<CustomComponent />
							<div className='section2 font8'>
								<div className='over_hid pad_t30'>
									<div className='fleft width49'>
										<div className='over_hid pad_b10'>
											<div className='fleft width7'>
												{' '}
												<strong>1.</strong>{' '}
											</div>
											<div className='fleft width93'>
												{' '}
												<strong>RECITAL</strong>
												<br />
												VIL is willing to provide the Session Initiation
												Protocol Central (“Services”) to the Customer on the
												terms and conditions (T&C) provided below which
												otherwise have been made available to Customer. Customer
												acknowledges that for availing the Services it agrees to
												comply with the T&C and is seeking to avail the Service
												herein. Basis the same, VIL shall provide the Services
												to the Customer and/or any person who for and on behalf
												of the Customer enterprise in its premises, makes use or
												avails the Services will be construed as a “User”.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'>
												{' '}
												<strong>2.</strong>{' '}
											</div>
											<div className='fleft width93'>
												{' '}
												<strong>PRECEDENCE</strong>{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 2.1 </div>
											<div className='fleft width93'>
												{' '}
												The GTC consists of following documents and their order
												of precedence in ascending order is: (i) Customer
												Application Form (“CAF”) duly completed (ii) Tariff
												Enrolment Form; (iii) Terms of Service; (iv) any other
												signed documents referenced.{' '}
											</div>
										</div>
										<div className='over_hid pad_b10'>
											<div className='fleft width7'> 2.2 </div>
											<div className='fleft width93'>
												{' '}
												In the event that the Parties wish to make any change to
												this GTC then they shall do so expressly in writing,
												which must be signed by authorised representatives of
												both Parties.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'>
												{' '}
												<strong>3.</strong>{' '}
											</div>
											<div className='fleft width93'>
												{' '}
												<strong>
													GENERAL TERMS APPLICABLE TO SERVICE
												</strong>{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 3.1 </div>
											<div className='fleft width93'>
												{' '}
												Customer shall ensure that: (i) the Services shall not
												be used for a call centre / other service provider (OSP)
												operations without registration with the Department of
												Telecommunication (DOT).{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 3.2 </div>
											<div className='fleft width93'>
												{' '}
												Except as expressly set out herein, VIL shall have right
												to: (i) charge Customers such fees/charges (if any) as
												may be determined from time to time; and (ii) promote /
												package the Service.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 3.3 </div>
											<div className='fleft width93'>
												{' '}
												For Services provisioned, Parties acknowledge and agree
												VIL acts as an intermediary and may not have
												supervisory, editorial rights over the Services provided
												to Customer{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 3.4 </div>
											<div className='fleft width93'>
												{' '}
												On violation of CAF terms or Applicable Laws for
												Services, VIL shall have the right to refuse, reject,
												suspend, remove or cause to be removed any Services that
												may be made available to Customers.{' '}
											</div>
										</div>
										<div className='over_hid pad_b10'>
											<div className='fleft width7'> 3.5 </div>
											<div className='fleft width93'>
												{' '}
												Customer shall not use or permit the Services for: (a)
												establishing a connection to the gateway (if procured by
												Customer) between Internet & PSTN/ISDN/PLMN as the same
												is not permitted in India; or (b) interconnectivity is
												not permitted for ISPs offering Internet Telephony
												Services and for ISPs usage; or (c) being used to route
												unauthorised telecommunication network including to
												prohibited destinations; or (d) lease/resell or create
												any third party right over the Services provisioned; or
												(e) negligence use by Customer, its employees or agents
												thereby being responsible for ensuring that the Services
												are used legally and in compliance with the existing
												regulations; or (f) excess or excessive usage or for any
												unusual patterns in the usage and/or for any
												unwarranted/not permitted usage; or (g) use, directly or
												indirectly, VIL’s network or infrastructure or any part
												thereof for sending bulk or unsolicited messages to VIL
												customers or other mobile phone users in any manner
												whatsoever; or (h) acquiring any customers or promote
												any messages through unethical practices or (i)
												modifying the location of Service or the CPE that is
												provided by VIL on rental basis under this Services,
												unless with prior written consent.{' '}
											</div>
										</div>
										<div className='over_hid pad_b10'>
											<div className='fleft width7'>
												{' '}
												<strong>4.</strong>{' '}
											</div>
											<div className='fleft width93'>
												{' '}
												<strong>DEFINITIONS</strong>{' '}
											</div>
										</div>
										<div className='termtable'>
											<table
												width='100%'
												border='0'
												cellspacing='0'
												cellpadding='0'
											>
												<tbody>
													<tr>
														<td>
															<strong>
																“Applicable
																<br />
																Law”
															</strong>
														</td>
														<td>
															means all laws, legislation, regulations, binding
															codes of practice, or rules or requirements of any
															relevant government or governmental agency
															applicable to the Services;
														</td>
													</tr>
													<tr>
														<td>
															<strong> “CAF” </strong>
														</td>
														<td>
															means VIL’s standard format of the CAF, as amended
															from time to time, contains regulatory conditions
															and / or instructions / or guidelines issued /
															mandated upon the Licensee under the Telecom
															Access Service License issued by Government of
															India{' '}
														</td>
													</tr>
													<tr>
														<td>
															<strong> “Services” </strong>
														</td>
														<td>
															{' '}
															means Session Initiation Protocol (“SIP”) is a
															communications protocol for signalling and
															controlling multimedia communication sessions.{' '}
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																“VIL Group
																<br />
																Company”{' '}
															</strong>
														</td>
														<td>
															{' '}
															mean a company that is directly or indirectly
															under the management control of Vodafone Idea
															Limited or entity provisioning of the Services;
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																“Service
																<br />
																Commencement Date”{' '}
															</strong>
														</td>
														<td>
															{' '}
															means the date when a Service is certified in
															writing by VIL as being active for transmission{' '}
														</td>
													</tr>
													<tr>
														<td>
															<strong>
																“Payment
																<br />
																Period”{' '}
															</strong>
														</td>
														<td>
															{' '}
															means the payment due date mentioned on the
															applicable invoice, but not later than thirty (30)
															calendar days from the date on which VIL India
															issues the invoice;
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className='grybg'>
											<div className='over_hid'>
												<div className='fleft width7'>
													{' '}
													<strong>5.</strong>{' '}
												</div>
												<div className='fleft width93'>
													{' '}
													<strong>OTHER TERMS AND CONDITIONS</strong>{' '}
												</div>
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.1 </div>
											<div className='fleft width93'>
												{' '}
												Either Party may terminate the Services on: - (a) on
												formal written notice and without liability, if other
												party becomes subject to (i) an Insolvency Event; or
												(ii) Force Majeure Event for (45) consecutive days; or
												(iii) convenience with prior (20) Business Days written
												notice, except if the termination is effective prior to
												Lock in Period then Exit Charge and any other sums will
												be payable; or (iv) commits a material breach, and
												failure to remedy such breach within 20 Business Days’;
												or (ii) commits a series of breaches which when taken
												together amount to a material breach. Material breach
												also shall construe: (i) Customer’s non-payment of an
												undisputed amount due beyond the Payment Period; (ii)
												any violation by the Customer or End User of Applicable
												Law; or (iii) any Service misuse contrary to telecom
												licence.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.2 </div>
											<div className='fleft width93'>
												{' '}
												If Services are terminated and there are unfulfilled or
												accrued obligations including payment of fees, expenses,
												indemnification and confidentiality then Parties shall
												be bound by those obligations and perform accordingly.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.3 </div>
											<div className='fleft width93'>
												{' '}
												Save as stated otherwise, the maximum aggregate
												liability (whether in contract or in tort) of each Party
												in each 12-month period (the first such period
												commencing and ending at the close of business before
												the first anniversary of the Service commencement) shall
												be limited to a sum equal to the value of total charges
												paid (excluding taxes and duties) for Services herein,
												during that period{' '}
											</div>
										</div>
									</div>
									<div className='flright width49'>
										<div className='over_hid'>
											<div className='fleft width7'> 5.4 </div>
											<div className='fleft width93'>
												{' '}
												Subject to clause 5.5, neither Party shall be liable to
												the other Party for any indirect, consequential,
												special, or exemplary losses or damages, including but
												not limited to loss of profits, loss of revenue, or loss
												of savings, whether actual or anticipated under or in
												connection with this GTC.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.5 </div>
											<div className='fleft width93'>
												{' '}
												Nothing in this GTC, excludes or limits liability in
												relation to: (i) any loss, damage, costs, and other
												liabilities (“Actions”) recoverable under Clause 5.13 &
												5.14 (Indemnification); (ii) Customer’s liability for
												repudiatory breach, wilful default and wilful
												abandonment; (iii) Customer’s obligation to pay all
												charges for the provision of Services; (iv) either
												Party’s liability for death or personal injury resulting
												from its negligence; (iv) either Party’s liability for
												fraud or fraudulent misrepresentation; (v) either
												Party’s liability which cannot be excluded by law; or
												(viii) the use of either Party’s Intellectual Property
												Rights, including any patents, designs, trademarks, and
												business names (including goodwill associated),
												copyright and related rights or forms of protection in
												any part of the world; by the other Party in breach of
												this GTC.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.6 </div>
											<div className='fleft width93'>
												{' '}
												Parties do hereby consent that all confidential
												information or documentation disclosed between them
												relating to business which is of a technical nature or
												involves research and development, that is rightfully
												obtained, will be kept under strict confidentiality and
												the recipient party shall not be permitted to reproduce
												any copies or ownership of the same. The obligation of
												confidentiality shall be valid for 3 years post
												termination or expiry of term.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.7 </div>
											<div className='fleft width93'>
												{' '}
												Each Party warrants that: (a) they are in possession of
												and shall maintain all necessary consents, approvals,
												permissions and licenses that are required for their
												performance obligations; and (b) each Party shall inform
												and cooperate as may be required for exchange relevant
												and necessary data and work out measures to prevent or
												eliminate fraud, misuse or damage and also in defending
												any claim threatened or brought by any Governmental
												Authorities or third party in relation to the matters
												arising out of this GTC{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.8 </div>
											<div className='fleft width93'>
												VIL makes no warranties or representations, express or
												implied, either in fact or by operation of law,
												statutory or otherwise, including warranties or terms of
												merchantability, satisfactory quality, fitness for
												purpose, title or non-infringement
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.9 </div>
											<div className='fleft width93'>
												Customer shall as a result of availing Services: (i) not
												knowingly or intentionally introduce software virus into
												VIL’s network; and (ii) take precautions to ensure that
												no virus is introduced; (iii) immediately report, if it
												finds a virus, and provide all information reasonably
												requested; and (iv) where a virus is detected, promptly
												take all steps necessary as agreed by VIL to support VIL
												in eliminating the virus. If Customer breaches the
												obligations in this clause it shall indemnify VIL
												against any Actions arising as a result of the breach.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.10 </div>
											<div className='fleft width93'>
												Customer shall co-operate or provide assistance and
												share correct, accurate and complete details to enable
												VIL to resolve any technical or system related issues
												pertaining to the Services, including by: (i) providing
												logs from its system to permit VIL to debug any
												technical issues; and (ii) engaging its equipment
												vendors on a need basis for joint trouble shooting
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.11 </div>
											<div className='fleft width93'>
												Customer shall, provide access to its network, premises
												or sites, inter alia, to: (i) diagnose any technical
												issues arising due to the equipment installed; (ii)
												inspect the premises or sites for resolution of Services
												outage; or (iii) improve the quality of the Services; or
												(iv) implement any changes in the provision of the
												Services; or (v) enable any technical trouble shooting ;
												or (vi) recovery of VIL provided customer premise
												equipment (CPE) in the event of termination of SIP
												Central services.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.12 </div>
											<div className='fleft width93'>
												Customer agrees to compensate for: (i) damage caused to
												VIL equipment in the event of any negligence or
												man-handling; or (ii) site visits made to resolve or
												isolate an issue pertaining to the Customer network.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.13 </div>
											<div className='fleft width93'>
												Customer shall indemnify and keep indemnified VIL
												against all claims, losses, demand, proceeding or
												damages or other liability from a third party claim,
												arising under or in connection with (i) any breach of
												the provisions on compliance with Applicable Law; or
												(ii) use of the Services for any purpose other than as
												specified in the applicable CAF; (iii) any breach of its
												confidentiality obligations; or (iv) in relation to
												negligence, fraud, wilful misconduct, breach of
												statutory duty
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.14 </div>
											<div className='fleft width93'>
												{' '}
												Customer shall indemnify and keep indemnified VIL (or
												its officers, employees, affiliates and their agents)
												against all Actions arising under or in connection with:
												(i) Customer’s or User’s: (a) use of the Services for
												any purpose other than as specified in the applicable
												CAF; or (b) transmission of illegal or unlawful content;
												or (c) infringement of privacy and data protection laws;
												(ii) fines/penalties imposed on VIL by Governmental
												Authorities resulting from Customer’s/User’s acts or
												omissions; (iii) any breach of the provisions on
												compliance with Applicable Law;.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.15 </div>
											<div className='fleft width93'>
												Each Party acknowledges that legally binding demands
												from a law enforcement authority for the disclosure of,
												or other assistance in respect of, Services or Personal
												Data (including any information identifying, directly or
												indirectly, the Customer, Users or employees), or be
												required by law, court order, warrant, subpoena, or
												other legal judicial process to disclose any data in
												this regard and in such circumstances it will not be
												construed as breach for complying with such obligations
												to the extent legally bound.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.16 </div>
											<div className='fleft width93'>
												Customer shall bear all risk of loss or damage to any
												part of the VIL network infrastructure at times when
												they are under Customer’s control.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.17 </div>
											<div className='fleft width93'>
												{' '}
												Formal written notices in English are given at the
												addresses for Service.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.18 </div>
											<div className='fleft width93'>
												{' '}
												Neither Party shall be responsible for failure due to an
												impeding ‘force majeure event’, provided that the
												subjected Party: (i) has promptly notified of the
												circumstances which may result in failure to perform its
												obligations; (ii) could not have avoided the effect of
												the force majeure event by taking precautions.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.19 </div>
											<div className='fleft width93'>
												{' '}
												This GTC shall be subject to laws of India, and courts
												of jurisdiction shall be of Mumbai, over any dispute,
												controversy or claim (including any question regarding
												its existence, validity or termination) arising herein.{' '}
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.20 </div>
											<div className='fleft width93'>
												Neither Party shall without prior written consent of
												other, not assign, novate, subcontract or otherwise
												transfer any of its rights or obligations, save that
												VILmay assign, novate, subcontract or otherwise transfer
												any of its rights or obligations herein to a VIL Group
												Company and also subcontract the provisioning of part of
												the Services.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.21 </div>
											<div className='fleft width93'>
												{' '}
												Customer shall raise order for Service by submitting an
												order form, subject to satisfaction of the verification
												process as identified in CAF.
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.22 </div>
											<div className='fleft width93'>
												{' '}
												All sums payable by Customer shall be without any
												deductions, withholdings and without any right of set
												off or counter claim, unless agreed otherwise
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.23 </div>
											<div className='fleft width93'>
												Customer agrees that the Services, detailed as annexed
												to the GTC/CAF, are acceptable and further agrees to pay
												the amount set out in the Tariff Enrolment Form in
												accordance with out payment conditions stated therein
											</div>
										</div>
										<div className='over_hid'>
											<div className='fleft width7'> 5.24 </div>
											<div className='fleft width93'>
												Any purchase order or other document issued by Customer
												is for administrative convenience only. In event of any
												inconsistency, the terms of this GTC shall prevail.
											</div>
										</div>
									</div>
								</div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<div className='auth margin0 over_hid pad_5t'>
									<div className='fleft width33'>
										Private and Confidential – Vi<sup>TM</sup> C2{' '}
									</div>
									<div className='fleft width33 textcenter'>
										– Vi<sup>TM</sup> SIP Central Services{' '}
									</div>
									<div className='fleft width33 text_r'>
										© Vodafone India Limited
									</div>
								</div>
							</div>
						</page>
					)}
				</div>
			</div>
		)
	} else {
		PriIPCentrixCafContentView = cafData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				Pri IpCentrix CAF Form not submitted yet.
			</div>
		)
	}
	return <div>{PriIPCentrixCafContentView}</div>
}

export default PriIPCentrixCafForm
